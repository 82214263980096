import axios, { AxiosInstance, InternalAxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';

import { appConfig } from '../appConfig.config';
import { LocalStorageKey } from '@chic/enums';

export const api: AxiosInstance = axios.create({
  baseURL: appConfig.api,
  responseType: 'json',
  ...(Object.keys(JSON.parse(window.localStorage.getItem(LocalStorageKey.LoggedUserToken) || '{}')).length
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ? { headers: { 'X-AUTH-TOKEN': JSON.parse(window.localStorage.getItem(LocalStorageKey.LoggedUserToken) || '{}') } }
    : {}
  ),
});

export const setAuthToken: (token: string) => void = (token: string): void => {
  const id: number = api.interceptors.request.use((request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const headers: RawAxiosRequestHeaders = request.headers ?? {};
    headers['X-AUTH-TOKEN'] = token;

    return request;
  });
  if (id > 0) {
    api.interceptors.request.eject(id - 1);
  }
};
