import { FrontendApiError, ApiReject, ApiResolve, LoginData, LoginErrorData, SimpleAuthData } from '@chic/models';
import { AxiosResponse } from 'axios';
import { api } from '../api.service';

export const classicLogin: (data: SimpleAuthData) => Promise<LoginData> = (data: SimpleAuthData): Promise<LoginData> => new Promise(
  (resolve: ApiResolve<LoginData>, reject: ApiReject<FrontendApiError<LoginErrorData>>): Promise<void> => (
    api.post('/auth/login', data)
      .then((response: AxiosResponse<LoginData>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const logout: () => Promise<void> = (): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject<FrontendApiError>): Promise<void> => (
    api.post('/auth/logout')
      .then((): void => resolve())
      .catch(reject)
  ),
);
