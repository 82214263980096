import React from 'react';

import { TransProps, useTranslation } from 'react-i18next';
import { WebsiteError } from '@chic/components';
import { useTitle } from '@chic/hooks';

export const AccessDeniedView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  useTitle(t('chic.crmApp.accessDeniedView.title'));

  return (
    <WebsiteError code={403} info={t('chic.crmApp.accessDeniedView.title')} />
  );
};
