import { ConfigsContextType, UseInitial } from '@chic/models';
import { useEffect, useState } from 'react';
import { appConfig } from '../appConfig.config';

export const useInitial: () => UseInitial = (): UseInitial => {
  const [configs, setConfigs] = useState<ConfigsContextType | null>(null);

  useEffect(
    (): void => {
      let configsValue: ConfigsContextType = {} as ConfigsContextType;
      let overridedConfigsValue: ConfigsContextType = {} as ConfigsContextType;

      const promises: Promise<void>[] = appConfig.configFilenames.map((configName: string): Promise<void> => {
        return new Promise((resolve: (value: void) => void): void => {
          import(`../configs/${configName}.config`)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((content: any) => {
              const fieldName: string = `${configName}Config`;
              configsValue = { ...configsValue, [configName]: content[fieldName] };
              resolve();
            })
            .catch((): void => undefined);
        });
      });

      const promisesForOverrided: Promise<void>[] = appConfig.overridedConfigFilenames.map((configName: string): Promise<void> => {
        return new Promise((resolve: (value: void) => void): void => {
          import(`../configs/overrides/${configName}.config`)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((content: any) => {
              const fieldName: string = `${configName}Config`;
              overridedConfigsValue = { ...overridedConfigsValue, [configName]: content[fieldName] };
              resolve();
            })
            .catch((): void => resolve());
        });
      });
      Promise.all([...promises, ...promisesForOverrided]).then((): void => setConfigs({ ...configsValue, ...overridedConfigsValue }));
    },
    [],
  );

  return { configs };
};
