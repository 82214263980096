import { IconName, ComponentColorTheme, ActionBoxButtonType, useRedirect, UseRedirect, DropdownOption, PageTitle } from '@chic-loyalty/ui';
import { PosAndDatePicker } from '@chic/components';
import { FilterKey, RoutingPath, UrlParams } from '@chic/enums';
import { stringify } from 'query-string';
import React, { useMemo } from 'react';
import { 
  DetailsContainer, 
  StyledAutosuggest, 
  BoxesContainer, 
  StyledPosDetails, 
  StyledMonthSummary, 
  DataBasesContainer, 
  StyledActionBox, 
} from './dashboardContent.styled';
import { useAuth } from '@chic/hooks';
import { Store, UseAuth, UseStores } from '@chic/models';
import { useStores } from 'src/hooks/useStores.hook';
import { TransProps, useTranslation } from 'react-i18next';

export const DashboardContent: React.FC = (): JSX.Element | null => {
  const { redirect }: UseRedirect = useRedirect();
  const { t }: TransProps<never> = useTranslation();
  const { isAdminLoggedIn, authData }: UseAuth = useAuth();
  const { 
    stores, 
    selectedStore, 
    onChooseStoreOwner, 
    onChooseDate, 
    initialStartDate,
    initialEndDate,
    selectedStoreOwner,
    storesOwners,
    onChooseStore,
    storeStats,
  }: UseStores = useStores();
  const isAdminLoggedInApp: boolean = useMemo((): boolean => isAdminLoggedIn(), [authData]);

  const posOptions: DropdownOption[] = useMemo(
    (): DropdownOption[] => {
      if (!stores.length) {
        return [];
      }

      return [
        { name: FilterKey.All, label: t('chic.franchisees.global.stores.all') },
        ...stores.map((store: Store): DropdownOption => ({ name: store.storeId.toString(), label: store.name })),
      ];
    },
    [stores],
  );

  if (selectedStore === undefined) {
    return null;
  }
  
  return (
    <>
      <DetailsContainer>
        {isAdminLoggedInApp && (
          <StyledAutosuggest 
            placeholder={t('chic.franchisees.dashboardContent.autosuggest.label')}
            iconName={IconName.Search} 
            suggestions={storesOwners}
            onSelect={onChooseStoreOwner}
            onValueInputChange={(): void => undefined}
            colorTheme={ComponentColorTheme.IC}
          />
        )}
        <PosAndDatePicker 
          posOptions={posOptions}
          selectedStore={selectedStore ?? null}
          onChooseStore={onChooseStore}
          onChooseDate={onChooseDate}
          initialStartDate={initialStartDate}
          initialEndDate={initialEndDate}
          stores={stores}
        />
        {selectedStore && selectedStore !== null ? (
          <BoxesContainer>
            <StyledPosDetails
              {...(selectedStore !== FilterKey.All && { 
                posName: selectedStore.name,
                posAddress: `${selectedStore.address} ${selectedStore.city}`,
                temporaryClosed: selectedStore.temporaryClosed,
                openingHours: selectedStore.openingHours,
              })}
              isEmpty={selectedStore === FilterKey.All}
              colorTheme={ComponentColorTheme.IC}
            />
            {!!storeStats.length && <StyledMonthSummary summaryRecords={storeStats} colorTheme={ComponentColorTheme.IC} />}
          </BoxesContainer>
        ) : t('chic.franchisees.global.withoutStore.message')}
      </DetailsContainer>
      <DataBasesContainer>
        <PageTitle label={t('chic.franchisees.dashboardContent.dataBases.text')} />
        <StyledActionBox 
          label={t('chic.franchisees.dashboardContent.actionBox.label.first')}
          text={t('chic.franchisees.dashboardContent.actionBox.text.first')}
          iconName={IconName.Subscription}
          buttonSettings={{
            action: (): void => redirect(
              `${RoutingPath.Subscriptions}?${stringify(
                { 
                  [UrlParams.Store]: selectedStore === FilterKey.All ? FilterKey.All : selectedStore?.storeId, 
                  [UrlParams.Owner]: selectedStoreOwner?.id, 
                },
              )}`,
            ), 
          }}
          colorTheme={ComponentColorTheme.IC}
          buttonType={ActionBoxButtonType.Arrow}
        />
        <StyledActionBox 
          label={t('chic.franchisees.dashboardContent.actionBox.label.second')}
          text={t('chic.franchisees.dashboardContent.actionBox.text.second')}
          iconName={IconName.Offers}
          buttonSettings={{
            action: (): void => redirect(
              `${RoutingPath.Activations}?${stringify(
                { 
                  [UrlParams.Store]: selectedStore === FilterKey.All ? FilterKey.All : selectedStore?.storeId, 
                  [UrlParams.Owner]: selectedStoreOwner?.id, 
                },
              )}`,
            ),  
          }}
          colorTheme={ComponentColorTheme.IC}
          buttonType={ActionBoxButtonType.Arrow}
        />
      </DataBasesContainer>
    </>
  );
};
