import React from 'react';

import { TransProps, useTranslation } from 'react-i18next';
import { WebsiteError } from '@chic/components';
import { useTitle } from '@chic/hooks';

export const NotFoundView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  useTitle(t('chic.crmApp.notFoundView.title'));

  return (
    <WebsiteError code={404} info={t('chic.crmApp.notFoundView.title')} />
  );
};
