import React, { useMemo } from 'react';
import { 
  ChildrenContainer, 
  Container, 
  DetailsContainer, 
  MetricBoxes, 
  MetricBoxesContainer, 
  PageHeaderContainer, 
  PageTitleWrapper, 
  StyledAutosuggest,
  StyledMetricBox, 
} from './baseStatsView.styled';
import { BaseStatsViewProps } from './baseStatsView.props';
import { ComponentColorTheme, DropdownOption, IconName, MetricBoxSettings, PageTitle, PageTitleSize } from '@chic-loyalty/ui';
import { PosAndDatePicker } from '../posAndDatePicker/posAndDatePicker.component';
import { useAuth } from '@chic/hooks';
import { Store, UseAuth } from '@chic/models';
import { TransProps, useTranslation } from 'react-i18next';
import { FilterKey } from '@chic/enums';

export const BaseStatsView: React.FC<BaseStatsViewProps> = (props: BaseStatsViewProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { isAdminLoggedIn, authData }: UseAuth = useAuth();
  const { 
    headerTitle, 
    onChooseStoreOwner, 
    stores, 
    selectedStore, 
    onChooseStore, 
    onChooseDate, 
    initialStartDate,
    initialEndDate,
    metricData,
    storesOwners,
    children, 
  }: BaseStatsViewProps = props;
  const isAdminLoggedInApp: boolean = useMemo((): boolean => isAdminLoggedIn(), [authData]);

  const posOptions: DropdownOption[] = useMemo(
    (): DropdownOption[] => {
      if (!stores.length) {
        return [];
      }

      return [
        { name: FilterKey.All, label: t('chic.franchisees.global.stores.all') },
        ...stores.map((store: Store): DropdownOption => ({ name: store.storeId.toString(), label: store.name })),
      ];
    },
    [stores],
  );

  return (
    <Container>
      <PageHeaderContainer>
        <PageTitleWrapper>
          <PageTitle label={headerTitle} size={PageTitleSize.Big} />
        </PageTitleWrapper>
        {isAdminLoggedInApp && (
          <StyledAutosuggest 
            placeholder={t('chic.franchisees.baseStatsView.autosuggest.label')}
            iconName={IconName.Search} 
            suggestions={storesOwners}
            onSelect={onChooseStoreOwner}
            onValueInputChange={(): void => undefined}
            colorTheme={ComponentColorTheme.IC}
          />
        )}
      </PageHeaderContainer>
      {selectedStore !== undefined && (
        <>
          <DetailsContainer>
            <PosAndDatePicker 
              posOptions={posOptions}
              selectedStore={selectedStore}
              onChooseStore={onChooseStore}
              onChooseDate={onChooseDate}
              initialStartDate={initialStartDate}
              initialEndDate={initialEndDate}
              stores={stores}
            />
            <MetricBoxesContainer>
              {selectedStore ? (
                <MetricBoxes>
                  {metricData.map((metricBox: MetricBoxSettings): JSX.Element => (
                    <StyledMetricBox
                      {...metricBox}
                      key={`${metricBox.label}-${metricBox.value}`}
                      colorTheme={ComponentColorTheme.IC}
                    />
                  ))}
                </MetricBoxes>
              ) : t('chic.franchisees.global.withoutStore.message')}
            </MetricBoxesContainer>
          </DetailsContainer>
          <ChildrenContainer>
            {children}
          </ChildrenContainer>
        </>
      )}
    </Container>
  );
};
