import React from 'react';
import { 
  Container, 
  DatePickerContainer, 
  EmptyPosList, 
  Label, 
  PosPicker, 
  StyledDatePicker, 
  StyledSmallDropdown, 
} from './posAndDatePicker.styled';
import { TransProps, useTranslation } from 'react-i18next';
import { PosAndDatePickerProps } from './posAndDatePicker.props';
import { ComponentColorTheme, DropdownOption } from '@chic-loyalty/ui';
import { Store } from '@chic/models';
import { FilterKey } from '@chic/enums';

export const PosAndDatePicker: React.FC<PosAndDatePickerProps> = (props: PosAndDatePickerProps): JSX.Element => {
  const { posOptions, selectedStore, onChooseStore, onChooseDate, initialStartDate, initialEndDate, stores }: PosAndDatePickerProps = props;
  const { t }: TransProps<never> = useTranslation();
  
  return (
    <Container>
      <PosPicker>
        <Label>{t('chic.franchisees.posAndDatePicker.posPicker.text')}</Label>
        {posOptions.length ? (
          <StyledSmallDropdown 
            options={posOptions}
            initialValue={selectedStore !== FilterKey.All && selectedStore 
              ? { 
                name: selectedStore.storeId.toString(), 
                label: selectedStore.name, 
              } 
              : {
                name: FilterKey.All, 
                label: t('chic.franchisees.global.stores.all'), 
              }}
            onChooseAnswer={(option: DropdownOption): void => onChooseStore(
              option.name === FilterKey.All 
                ? FilterKey.All 
                : stores.find((store: Store): boolean => String(store.storeId) === option.name) ?? null,
            )} 
            colorTheme={ComponentColorTheme.IC}
          />
        ) : <EmptyPosList>{t('chic.franchisees.posAndDatePicker.posPicker.emptyPosOptions')}</EmptyPosList>}
      </PosPicker>
      <DatePickerContainer>
        <Label>{t('chic.franchisees.posAndDatePicker.datePicker.text')}</Label>
        {!!initialStartDate && !!initialEndDate && (
          <StyledDatePicker 
            onChoose={onChooseDate}
            startMinDate={initialStartDate}
            endMaxDate={initialEndDate}
            colorTheme={ComponentColorTheme.IC}
            withRangeSelection
          />
        )}
      </DatePickerContainer>
    </Container>
  );
};
