import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { Location } from 'history';

import { authWithCrmCode } from '@chic/api';
import { useLocation } from 'react-router-dom';
import { FrontendApiError, LoginData, UseAuth } from '@chic/models';
import { useAuth } from '@chic/hooks';

import { Container, Message } from './crmSignInAfterRedirect.styled';
import { RoutingPath } from '@chic/enums';
import { Color, ComponentColorTheme, Loader, UseRedirect, UseState, useRedirect } from '@chic-loyalty/ui';

export const CrmSignInAfterRedirectView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { signIn }: UseAuth = useAuth();
  const { search }: Location = useLocation();
  const { redirect }: UseRedirect = useRedirect();
  const [message, setMessage]: UseState<string> = useState<string>('');
  const query: URLSearchParams = new URLSearchParams(search);
  const code: string | null = query.get('code');

  useEffect(
    (): void => {
      if (code) {
        authWithCrmCode({ code })
          .then((data: LoginData): void => {
            signIn({ user: data.user, token: data.token });
          })
          .catch((error: FrontendApiError): void => {
            if (error.message) {
              setMessage(error.message);
            }
            setTimeout((): void => redirect(RoutingPath.CrmSignIn), 3000);
          });
      }
    },
    [],
  );

  return (
    <Container>
      {!message && <Loader colorTheme={ComponentColorTheme.IC} color={Color.ICYellow100} size={36} />}
      <Message>
        {message || t('chic.crmApp.crmSignInAfterRedirectView.message')}
      </Message>
    </Container>
  );
};
