import { Breakpoint, CalcSize, Color, FontWeight, Grid, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  background-color: ${Color.ICBlack500};
  overflow: hidden;
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 24px 24px 0;
`;

export const PageTitleDescription: StyledComponent<'p'> = styled.p`
  font-size: 14px; 
  font-weight: ${FontWeight.ExtraBold};
  line-height: 24px;
  color: ${Color.ICYellow100};
  flex: 0 0 100%;
  margin: 8px 0 0 48px;

  @media ${Breakpoint.Mobile} {
    margin: 8px 0 0 28px;
  }
`;
