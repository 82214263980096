import { KeyValue } from '@chic/models';

export const keyValuesToQueryParams = (object: KeyValue): string => {
  return Object.keys(object)
    .reduce(
      (prev: string, paramKey: string) => {
        if (object[paramKey] !== 'undefined' && object[paramKey] !== undefined) {
          return `${prev}&${paramKey}=${object[paramKey]}`;
        } else {
          return `${prev}`;
        }
      },
      '',
    );
};
