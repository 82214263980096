import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { PermissionScopeName, ProfileTabName } from '@chic/enums';
import { useTitle, useAuth } from '@chic/hooks';

import { PasswordChange, PersonalData } from './components';
import { Container, InnerContainer, PageTitleWrapper } from './profile.styled';
import { ComponentColorTheme, IconName, PageTitle, PageTitleSize, TabSwitch, TabSwitchOption, UseState } from '@chic-loyalty/ui';
import { UseAuth } from '@chic/models';

export const ProfileView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  useTitle(t('chic.crmApp.profile.title'));
  const { authData }: UseAuth = useAuth();
  const [activeTab, setActiveTab]: UseState<string> = useState<string>(ProfileTabName.YourData);

  const changePasswordEnabled: boolean = useMemo(
    (): boolean => {
      return !!authData.user?.scopes.includes(PermissionScopeName.UserVerificationVerify) && !authData.user.crmId;
    },
    [authData.user],
  );

  const tabs: TabSwitchOption[] = useMemo(
    (): TabSwitchOption[] => {
      return [
        {
          name: ProfileTabName.YourData,
          icon: IconName.Help,
          labels: [t('chic.crmApp.profile.tabs.yourData.label')],
        },
        {
          name: ProfileTabName.PasswordChange,
          icon: IconName.NotificationIcSystem,
          labels: [t('chic.crmApp.profile.tabs.passwordChange.label')],
        },
      ];
    },
    [],
  );

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle size={PageTitleSize.Big} label={t('chic.crmApp.profile.pageTitle')} />
      </PageTitleWrapper>
      <InnerContainer>
        {changePasswordEnabled && (
          <TabSwitch 
            tabs={tabs} 
            onTabChange={setActiveTab} 
            colorTheme={ComponentColorTheme.IC} 
            initialTabName={activeTab} 
          />
        )}
        {activeTab === ProfileTabName.YourData && <PersonalData />}
        {activeTab === ProfileTabName.PasswordChange && <PasswordChange />}
      </InnerContainer>
    </Container>
  );
};
