export enum ErrorMessageType {
  RoleBlank = 'role_blank',
  RoleInvalid = 'role_invalid',
  LoginBlank = 'login_blank',
  LoginInvalid = 'login_invalid',
  NameBlank = 'name_blank',
  NameMaxLength = 'name_max_length',
  SurnameBlank = 'surname_blank',
  SurnameMaxLength = 'surname_max_length',
  LoginExist = 'login_exist',
  LoginMinLength = 'login_min_length',
  LoginMaxLength = 'login_max_length',
  EmailExist = 'email_exist',
  EmailBlank = 'email_blank',
  EmailInvalid = 'email_invalid',
  PasswordMinLength = 'password_min_length',
  PasswordMaxLength = 'password_max_length',
  PasswordBlank = 'password_blank',
  PasswordInvalidMatch = 'password_invalid_match',
  PasswordInvalidCurrent = 'password_invalid_current',
  PasswordHistorySame = 'password_history_same',
  TokenBlank = 'token_blank',
  TokenInvalid = 'token_invalid',
  RedundantFields = 'redundant_fields',
  PasswordWeak = 'password_weak',
  ActiveInvalid = 'active_invalid',
}
