import { BaseStatsView } from '@chic/components';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { ContentWrapper, InputsContainer, Message, StyledInput, StyledPagination, StyledSmallDropdown } from './activations.styled';
import { QueryObserverResult, useQuery } from 'react-query';
import { FilterKey, QueryKey } from '@chic/enums';
import { useStores } from '../../hooks/useStores.hook'; // TODO: find out why index import don't work and crash builds
import { ListData, Store, StoreActivation, UseStores } from '@chic/models';
import { getStoreActivations } from '@chic/api';
import { 
  ComponentColorTheme, 
  DropdownOption, 
  FranchiseDetailsTable, 
  FranchiseRowTheme, 
  FranchiseTableCellTheme, 
  FranchiseTableCellType, 
  FranchiseTableRow, 
  IconName,  
  PageTitle, 
  PaginationDetails, 
  UseState, 
  VisibilitySettings, 
  usePagination, 
} from '@chic-loyalty/ui';
import { emptyRequest } from '@chic/utils';
import { ActivationsFranchiseTableName } from './activations.enums';

export const ActivationsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { 
    selectedStore, 
    onChooseStoreOwner, 
    employees, 
    stores,
    onChooseStore, 
    onChooseDate, 
    initialStartDate,
    initialEndDate,
    activationsMetricData,
    storesOwners,
    startDate,
    endDate,
    selectedStoreOwner,
  }: UseStores = useStores();
  const { setMaxItems, pagesCount, page, setPage, itemsPerPage, offset }: PaginationDetails = usePagination(); 
  const [activationsFranchiseTable, setActivationsFranchiseTable]: UseState<FranchiseTableRow[] | null> 
    = useState<FranchiseTableRow[] | null>(null);
  const [selectedEmployee, setSelectedEmployee]: UseState<string> = useState<string>('');
  const [promotion, setPromotion]: UseState<string> = useState<string>('');

  useEffect(
    (): void => {
      if (promotion.length) {
        setPromotion('');
      }
      if (selectedEmployee.length) {
        setSelectedEmployee('');
      }
    },
    [selectedStore, selectedStoreOwner],
  );

  const onPromotionChange: (value: string) => void = (value: string): void => {
    setPromotion(value);
  };

  const onDebouncedPromotionChange: (value: string) => void = useCallback(debounce(onPromotionChange, 500), []);

  const { isFetched }: QueryObserverResult = useQuery(
    [QueryKey.StoreActivations, selectedStore, stores, promotion, selectedEmployee, page, startDate, endDate],
    (): Promise<ListData<StoreActivation> | void> => selectedStore
      ? getStoreActivations(
        selectedStore === FilterKey.All
          ? stores.map((store: Store): number => store.storeId)
          : [selectedStore.storeId],  
        startDate ?? initialStartDate,
        endDate ?? initialEndDate,
        promotion, 
        selectedEmployee && selectedEmployee !== 'all' ? Number(selectedEmployee) : undefined,
        itemsPerPage,
        offset,
        page,
      )
      : emptyRequest(),
    {
      onSuccess: (data: ListData<StoreActivation> | void): void => {
        if (!data) {
          setActivationsFranchiseTable([]);
          return;
        }

        setMaxItems(data.count);
        setActivationsFranchiseTable([
          ...data.elements.map((element: StoreActivation): FranchiseTableRow => ({
            theme: FranchiseRowTheme.Normal,
            cells: [
              {
                name: ActivationsFranchiseTableName.UseDatetime,
                value: String(new Date(element.useDatetime).getTime()),
                cellTheme: FranchiseTableCellTheme.ICWhite,
                cellType: FranchiseTableCellType.Date,
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: ActivationsFranchiseTableName.CustomerEmail,
                value: element.customer?.email ?? '-',
                cellTheme: FranchiseTableCellTheme.ICWhite,
                cellType: FranchiseTableCellType.Text,
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: ActivationsFranchiseTableName.CustomerEan,
                value: element.customer?.ean ?? '-',
                cellTheme: FranchiseTableCellTheme.ICWhite,
                cellType: FranchiseTableCellType.Text,
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: ActivationsFranchiseTableName.SellerName,
                value: element.seller ? element.seller.name : '-',
                cellTheme: FranchiseTableCellTheme.ICWhite,
                cellType: FranchiseTableCellType.Text,
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: ActivationsFranchiseTableName.PromotionCode,
                value: element.promotion.code,
                cellTheme: FranchiseTableCellTheme.ICYellow,
                cellType: FranchiseTableCellType.Text,
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: ActivationsFranchiseTableName.PromotionName,
                value: element.promotion.name,
                cellTheme: FranchiseTableCellTheme.ICWhite,
                cellType: FranchiseTableCellType.Text,
                tooltipContent: element.promotion.description,
              }, 
              {
                name: ActivationsFranchiseTableName.PromotionDescription,
                value: element.promotion.description,
                cellTheme: FranchiseTableCellTheme.ICWhite,
                cellType: FranchiseTableCellType.Text,
                visibilitySettings: VisibilitySettings.OnlyMobile,
              },
            ],
          })),
        ]);
      },
    },
  );

  return (
    <BaseStatsView 
      headerTitle={t('chic.franchisees.activationsView.headerTitle')} 
      onChooseStoreOwner={onChooseStoreOwner}
      selectedStore={selectedStore}
      stores={stores}
      onChooseStore={onChooseStore}
      onChooseDate={onChooseDate}
      initialStartDate={initialStartDate}
      initialEndDate={initialEndDate}
      metricData={activationsMetricData}
      storesOwners={storesOwners}
    >
      <ContentWrapper>
        <PageTitle label={t('chic.franchisees.activationsView.contentWrapper.title')} />
        <InputsContainer>
          <StyledInput 
            placeholder={t('chic.franchisees.activationsView.contentWrapper.input.placeholder')}
            colorTheme={ComponentColorTheme.IC}
            onChange={onDebouncedPromotionChange}
            icon={IconName.Search}
          />
          {!!employees.length && (
            <StyledSmallDropdown
              options={employees}
              initialValue={
                !selectedEmployee 
                  ? employees.find((employee: DropdownOption): boolean => employee.name === 'all')
                  : employees.find((employee: DropdownOption): boolean => employee.name === selectedEmployee)
              }
              onChooseAnswer={(option: DropdownOption): void => setSelectedEmployee(option.name)} 
              label={t('chic.franchisees.activationsView.smallDropdown.label')}
              colorTheme={ComponentColorTheme.IC}
            />
          )}
        </InputsContainer>
        {activationsFranchiseTable !== null && isFetched && (
          activationsFranchiseTable.length && !!selectedStore ? (
            <>
              <FranchiseDetailsTable 
                headers={[
                  {
                    name: ActivationsFranchiseTableName.UseDatetime,
                    label: t('chic.franchisees.activationsView.franchiseDetailsTable.headers.useDatetime'),
                  }, 
                  {
                    name: ActivationsFranchiseTableName.CustomerEmail,
                    label: t('chic.franchisees.activationsView.franchiseDetailsTable.headers.customerEmail'),
                  }, 
                  {
                    name: ActivationsFranchiseTableName.CustomerEan,
                    label: t('chic.franchisees.activationsView.franchiseDetailsTable.headers.customerEan'),
                  }, 
                  {
                    name: ActivationsFranchiseTableName.SellerName,
                    label: t('chic.franchisees.activationsView.franchiseDetailsTable.headers.sellerName'),
                  }, 
                  {
                    name: ActivationsFranchiseTableName.PromotionCode,
                    label: t('chic.franchisees.activationsView.franchiseDetailsTable.headers.promotionCode'),
                  },
                  {
                    name: ActivationsFranchiseTableName.PromotionName,
                    label: t('chic.franchisees.activationsView.franchiseDetailsTable.headers.promotionName'),
                  },
                  {
                    name: ActivationsFranchiseTableName.PromotionDescription,
                    label: t('chic.franchisees.activationsView.franchiseDetailsTable.headers.promotionDescription'),
                  },
                ]} 
                tableData={activationsFranchiseTable}
                columnsVisibleOnMobile={[ActivationsFranchiseTableName.UseDatetime, ActivationsFranchiseTableName.SellerName]} 
                colorTheme={ComponentColorTheme.IC}
              />
              <StyledPagination 
                activePage={page} 
                pagesCount={pagesCount} 
                onActivePageChange={setPage} 
                colorTheme={ComponentColorTheme.IC} 
              />
            </>
          ) : (
            <Message>{t('chic.franchisees.activationsView.emptyTable.message')}</Message>
          )
        )}
      </ContentWrapper>
    </BaseStatsView>
  );
};
