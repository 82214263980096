import { useContext } from 'react';

import { ConfigsContext } from '@chic/contexts';
import { ConfigsContextType } from '@chic/models';

export const useConfig: () => ConfigsContextType = (): ConfigsContextType => {
  const config: ConfigsContextType = useContext(ConfigsContext);
  if (!config) {
    throw new Error('Cannot use config context outside config.provider');
  }

  return config;
};
