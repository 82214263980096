import { PermissionScopeName, RoutingPath } from '@chic/enums';
import { ConfigsContextType, MenuPosition, UseRoutingPermissions } from '@chic/models';
import { useAuth } from './useAuth.hook';
import { useConfig } from './useConfig.hook';

export const useRoutingPermissions: () => UseRoutingPermissions = (): UseRoutingPermissions => {
  const { authData } = useAuth();
  const config: ConfigsContextType = useConfig();

  const isMenuPositionAvailable = (position: MenuPosition): boolean => {
    if (
      position.requiredPermissions
      && !position.requiredPermissions.every((scope: PermissionScopeName) => authData.user?.scopes.includes(scope))
    ) {
      return false;
    }
    if (position.pathname && config.disabledViews.includes(position.pathname)) {
      return false;
    }

    return true;
  };

  const checkRoutePermission: (pathname: RoutingPath | string) => boolean = (pathname: RoutingPath | string): boolean => {
    if (authData.user) {
      switch (pathname) {
        // common paths
        case RoutingPath.Home:
        case RoutingPath.Dashboard:
          return authData.user.scopes.includes(PermissionScopeName.AuthUser);
        case RoutingPath.Profile:
          return authData.user.scopes.includes(PermissionScopeName.AuthUserProfile);
        case RoutingPath.NotFound:
          return true;
        case RoutingPath.UserRoleVerification:
          return authData.user.scopes.includes(PermissionScopeName.UserVerificationVerify);
        case RoutingPath.UserRoleVerificationList:
          return authData.user.scopes.includes(PermissionScopeName.UserVerificationList);
          // custom paths for forks apps
        case RoutingPath.Activations:
          return authData.user.scopes.includes(PermissionScopeName.FranchiseStoreActivations);
        case RoutingPath.Subscriptions:
          return authData.user.scopes.includes(PermissionScopeName.FranchiseStoreSubscriptions);
        default:
          return false;
      }
    }

    return false;
  };

  return { checkRoutePermission, isMenuPositionAvailable };
};
