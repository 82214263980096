import styled from 'styled-components';
import { AppContainerProps, ContainerProps } from './appWrapper.props';
import { Breakpoint, CalcSize, Color, StyledComponent } from '@chic-loyalty/ui';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  width: 100%;
  background-color: ${Color.ICBlack500};
  display: flex;
  height: ${({ $withFixedHeight }: ContainerProps): string => $withFixedHeight ? '100vh' : '100%'};
`;

export const AppContainer: StyledComponent<'div', AppContainerProps> = styled.div<AppContainerProps>`
  width: ${({ $hasPadding }: AppContainerProps): string => $hasPadding ? `calc(100% - ${CalcSize.AttachedVerticalMenuWidth})` : '100%'};

  @media ${Breakpoint.Tablet} {
    width: 100%;
  }
`;
