import { datadogLogs } from '@datadog/browser-logs';

import { ErrorName } from '@chic/enums';
import { UseAuth, UseLogger } from '@chic/models';

import { appConfig } from '../appConfig.config';
import { useAuth } from './useAuth.hook';

export const useLogger = (): UseLogger => {
  const { authData, isLoggedIn }: UseAuth = useAuth();
  const logger = (errorName: string, errorDetails: Object): void => {
    if (appConfig.logsSystemClientToken) {
      datadogLogs.logger.error(
        `[App error - ${errorName}]`,
        {
          userId: isLoggedIn ? authData.user?.id : null,
          role: isLoggedIn ? authData.user?.role : null,
          appErrorDetails: errorDetails,
        },
      );
    }

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(`[App error - ${errorName}]`, errorDetails);
    }
  };
  
  const windowOnErrorHandler: OnErrorEventHandler = (
    event: Event | string, source?: string, lineNo?: number, columnNo?: number, error?: Error,
  ): boolean => {
    const errorDetails: Object = {
      ...{ event },
      ...{ source },
      ...{ lineNo },
      ...{ columnNo },
      errorObject: error,
    };
  
    logger(ErrorName.GlobalError, errorDetails);
    return true; // When the function returns true, this prevents the firing of the default event handler.
  };
  
  const windowOnUnhandledRejectionHandler = (event: PromiseRejectionEvent): void => {  
    logger(ErrorName.UnhandledRejection, { 'reason': event.reason });
  };
  
  const globalErrorHandler = (): void => {
    window.onerror = windowOnErrorHandler;
    window.onunhandledrejection = windowOnUnhandledRejectionHandler;
  };

  return { logger, globalErrorHandler };
};
