import { initialAppState } from '@chic/constans';
import { AppStateAction } from '@chic/enums';
import { AppState, AppStateActions } from '@chic/models';

export const appStateReducer = (state: AppState, action: AppStateActions): AppState => {
  switch (action.type) {
    // common
    case AppStateAction.SetFullscreenAlertData:
      return { ...state, fullscreenAlertData: action.payload };
    case AppStateAction.SetStateToInitial:
      return { ...initialAppState };
    // custom for forks apps
    default:
      return state;
  }
};
