import React, { useEffect } from 'react';

import { useAuth } from '@chic/hooks';

import {
  Container,
  MessageContainer,
  MessageHeader,
  MessageContent,
  NoRoleImage,
  OuterContainer,
} from './noRoleBox.styled';
import NoRoleIcon from './images/noRoleIcon.svg';
import { NoRoleBoxProps } from './noRoleBox.types';
import { getUserData } from '@chic/api';
import { User } from '@chic/models';
import { LocalStorageKey } from '@chic/enums';
import { TransProps, useTranslation } from 'react-i18next';
import { UseLocalStorage, useLocalStorage } from '@chic-loyalty/ui';

export const NoRoleBox: React.FC<NoRoleBoxProps> = (props: NoRoleBoxProps): JSX.Element => {
  const { children } = props;
  const { authData } = useAuth();
  const { t }: TransProps<never> = useTranslation();
  const [, storeAuthData]: UseLocalStorage<string | null> = useLocalStorage<string | null>(LocalStorageKey.LoggedUserData, '');

  if (!authData.user?.verified) {
    useEffect(
      (): void => {
        if (authData.token) {
          getUserData().then((data: User): void => {
            storeAuthData(JSON.stringify({ ...authData, ...{ user: data } }));
          });
        }
      },
      [],
    );

    return (
      <OuterContainer>
        <Container>
          <MessageContainer>
            <MessageHeader>{t('chic.crmApp.dashboardView.noRoleBox.messageHeader')}</MessageHeader>
            <MessageContent>
              {t('chic.crmApp.dashboardView.noRoleBox.messageContent')}
            </MessageContent>
          </MessageContainer>
          <NoRoleImage src={NoRoleIcon} />
        </Container>
      </OuterContainer>
    );
  }

  return (
    <>
      {children}
    </>
  );
};
