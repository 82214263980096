import { ApiError } from '@chic-loyalty/ui';
import { ApiResolve, ApiReject, StorePromotion } from '@chic/models';
import { AxiosResponse } from 'axios';
import { api } from '../api.service';

export const getPromotionsList: () => Promise<StorePromotion[]> = (): Promise<StorePromotion[]> => new Promise(
  (resolve: ApiResolve<StorePromotion[]>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get('/franchise/promotions').then(
      (response: AxiosResponse<StorePromotion[]>): void => { resolve(response?.data); },
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  ),
);
