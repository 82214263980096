import { UserRoleVerificationListState } from './userRoleVerificationList.types';

export const userRoleVerificationListInitialState: UserRoleVerificationListState = {
  sortKey: undefined,
  sortOrder: undefined,
  filterCrmIdValue: undefined,
  filterNameValue: undefined,
  filterEmailValue: undefined,
  filterPhoneValue: undefined,
};
