import { RoutingPath } from '@chic/enums';

export const getRouteName: (url: RoutingPath | string | undefined) => string | undefined = (
  url: RoutingPath | string | undefined,
): string | undefined => {
  if (!url) {
    return;
  }

  return Object.entries(RoutingPath)
    .find((route: [string, RoutingPath]): boolean => route[1] === url)
    ?.[0];
};
