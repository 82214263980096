import { ApiReject, ApiResolve, CrmAuth, LoginData } from '@chic/models';
import { AxiosResponse } from 'axios';
import { api } from '../api.service';

export const authWithCrmCode: (data: CrmAuth) => Promise<LoginData> = (data: CrmAuth): Promise<LoginData> => new Promise(
  (resolve: ApiResolve<LoginData>, reject: ApiReject): Promise<void> => (
    api.post('/crm/auth', data)
      .then((response: AxiosResponse<LoginData>): void => resolve(response?.data))
      .catch(reject)
  ),
);
