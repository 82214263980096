import { Breakpoint, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 10px;

  @media ${Breakpoint.SmallMobile} {
    flex-wrap: wrap;
    height: 100%;
  }
`;
