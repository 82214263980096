export enum QueryKey {
  PromotionsList = 'promotionsList',
  StoreActivations = 'storeActivations',
  StoreEmployees = 'storeEmployees',
  StoreOwners = 'storeOwners',
  StoreSubscriptions = 'storeSubscriptions',
  StoreStats = 'storeStats',
  StoresByNip = 'storesByNip',
  UserRoles = 'userRoles',
  UsersVerificationList = 'usersVerificationList',
}
