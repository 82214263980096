import styled from 'styled-components';

import { Breakpoint, Color, FontWeight, Grid, StyledComponent } from '@chic-loyalty/ui';

export const OuterContainer: StyledComponent<'div'> = styled.div`
  padding: 70px 20px 167px;
  display: flex;
  justify-content: center;
  background-color: ${Color.ICBlack500};

  @media ${Breakpoint.Mobile} {
    padding: 20px 0 0;
  }
`;

export const Container: StyledComponent<'div'> = styled.div`
  padding: 112px 130px 112px 110px;
  display: flex;
  justify-content: space-between;
  background-color: ${Color.ICBlack400};
  width: 100%;
  max-width: ${Grid.ICSmallDesktop};
  border-radius: 10px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    align-items: flex-end;
    padding: 35px 50px 47px 35px;
    margin: 0 auto;
    width: calc(100% - 40px);
  }
`;

export const MessageContainer: StyledComponent<'div'> = styled.div``;

export const MessageHeader: StyledComponent<'div'> = styled.div`
  font-size: 24px;
  line-height: 32px;
  color: ${Color.ICYellow100};
  font-weight: ${FontWeight.Bold};
  margin-bottom: 20px;
`;

export const MessageContent: StyledComponent<'div'> = styled.div`
  font-size: 18px;
  line-height: 32px;
  color: ${Color.ICWhite100};
  font-weight: ${FontWeight.Light};
`;

export const NoRoleImage: StyledComponent<'img'> = styled.img`
  margin-left: 20px;
  width: 247px;

  @media ${Breakpoint.Mobile} {
    width: 158px;
    margin: 0;
    margin-top: 70px;
  }
`;
