import { AxiosResponse } from 'axios';

import { ApiReject, ApiResolve, TokenWithServerDate, User, UserProfile } from '@chic/models';
import { api } from '../api.service';
import { userTokenInfoEndpoint } from '@chic/constans';

export const getUserData: () => Promise<User> = (): Promise<User> => new Promise(
  (resolve: ApiResolve<User>, reject: ApiReject): Promise<void> => (
    api.get('/user')
      .then((response: AxiosResponse<User>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getUserProfile: () => Promise<UserProfile> = (): Promise<UserProfile> => new Promise(
  (resolve: ApiResolve<UserProfile>, reject: ApiReject): Promise<void> => (
    api.get('/user/profile')
      .then((response: AxiosResponse<UserProfile>): void => resolve(response?.data))
      .catch(reject)
  ),
);

// WARN: only this endpoint doesn't extend duration of the login session
export const getUserTokenInfo: () => Promise<TokenWithServerDate> = (): Promise<TokenWithServerDate> => new Promise(
  (resolve: ApiResolve<TokenWithServerDate>, reject: ApiReject): Promise<void> => (
    api.get(userTokenInfoEndpoint)
      .then((response: AxiosResponse<TokenWithServerDate>): void => resolve(response?.data))
      .catch(reject)
  ),
);
