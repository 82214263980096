import { Breakpoint, Color, DatePicker, FontWeight, Grid, SmallDropdown, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  max-width: ${Grid.ICSmallDesktop};
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  gap: 12px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    border-bottom: none;
    padding: 0;
    margin: 30px 0;
  }
`;

export const Label: StyledComponent<'div'> = styled.div`
  font-size: 14px; 
  color: ${Color.ICWhite100};
  line-height: 20px;
  margin-bottom: 6px;
`;

export const PosPicker: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    width: 100%;
  }
`;

export const StyledSmallDropdown: StyledComponent<typeof SmallDropdown> = styled(SmallDropdown)`
  width: 100%;
`;

export const StyledDatePicker: StyledComponent<typeof DatePicker> = styled(DatePicker)`
  width: 100%;
`;

export const EmptyPosList: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Bold};
  color: ${Color.ICYellow100};

  @media ${Breakpoint.Mobile} {
    margin-left: 0;
    padding: 12px 0;
  }
`;  

export const DatePickerContainer: StyledComponent<'div'> = styled.div`
  width: 50%;

  @media ${Breakpoint.Mobile} {
    width: 100%;
    margin-top: 12px;
  }
`;
