export enum PermissionScopeName {
  // common paths
  AuthUser = 'auth_user',
  AuthUserScopes = 'auth_user_scopes',
  AuthUserUpdateSelfPassword = 'auth_user_update_self_password',
  Scopes = 'scopes',
  AuthUserProfile = 'auth_user_profile',
  ActiveRolesList = 'active_roles_list',
  UserVerificationList = 'user_verification_list',
  UserCrmDetails = 'user_crm_details',
  UserVerificationVerify = 'user_verification_verify',
  ExternalUserCreate = 'external_user_create',
  ExternalUserList = 'external_user_list',
  ExternalUserActivity = 'external_user_activity',
  // custom paths for forks apps
  FranchiseStoreOwnersList = 'franchise_store_owners_list',
  FranchiseStoreList = 'franchise_store_list',
  FranchiseStoreDetails = 'franchise_store_details',
  FranchiseStoreStats = 'franchise_store_stats',
  FranchiseStoreSubscriptions = 'franchise_store_subscriptions',
  FranchiseCacheLog = 'franchise_cache_log',
  FranchiseSubscriptionsPlansList = 'franchise_subscriptions_plans_list',
  FranchiseSubscriptionsPlansDetails = 'franchise_subscriptions_plans_details',
  FranchiseStoreEmployees = 'franchise_store_employees',
  FranchisePromotionsList = 'franchise_promotions_list',
  FranchisePromotionsDetails = 'franchise_promotions_details',
  FranchiseStoreActivations = 'franchise_store_activations',
}
