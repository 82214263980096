import styled from 'styled-components';

import { Button, CalcSize, Color, Input, Markdown, StyledComponent } from '@chic-loyalty/ui';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  background-color: ${Color.ICBlack500};
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  padding: 24px;
  width: 100%;
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  padding: 88.5px 24px;
  flex: 1;
  height: 100%;
  width: 100%;
  max-width: calc(838px + 48px);
`;

export const Description: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 20px;
  line-height: 20px;
  color: ${Color.ICWhite100};
  margin-bottom: 40px;
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  width: 100%;

  & + & {
    margin-top: 8px;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 40px;
`;
