import { Breakpoint, CalcSize, Color, FontWeight, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';
import { ContainerProps } from './websiteError.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - ${CalcSize.FooterHeight} - ${CalcSize.HealthWarningHeight});
  background: url('${({ $img }: ContainerProps): string => $img}');
  background-size: cover;
  background-position: right;

  @media ${Breakpoint.Tablet} {
    min-height: calc(100vh - ${CalcSize.TabletFooter}} - ${CalcSize.StickyHeader});
  }
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const Code: StyledComponent<'div'> = styled.div`
  font-size: 300px; 
  font-weight: ${FontWeight.Bold}; 
  letter-spacing: 0.4px; 
  color: ${Color.ICWhite100};

  @media ${Breakpoint.Mobile} {
    font-size: 140px;
  }
`;

export const Info: StyledComponent<'div'> = styled.div`
  margin: 0 0 40px;
  font-size: 44px;
  font-weight: ${FontWeight.SemiBold};
  color: ${Color.ICWhite100};
  letter-spacing: 0.4px; 

  @media ${Breakpoint.Mobile} {
    font-size: 24px;
  }
`;
