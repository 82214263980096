import { AuthStateAction } from '@chic/enums';
import { AuthState, AuthStateActions } from '@chic/models';

export const authReducer = (state: AuthState, action: AuthStateActions): AuthState => {
  switch (action.type) {
    case AuthStateAction.SignIn:
      return { ...state, ...action.payload };
    case AuthStateAction.SignOut:
      return { ...state, token: null, user: null };
    default:
      return state;
  }
};
