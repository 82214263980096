export enum RoutingPath {
  // common paths
  Home = '/',
  ReturnFromCrm = '/login/crm',
  Dashboard = '/panel',
  SignIn = '/logowanie-wewnetrzne',
  RemindPassword = '/przypomnij-haslo',
  ChangePassword = '/zmiana-hasla',
  CrmSignIn = '/logowanie',
  CrmSignInRedirect = '/zaloguj-przez-crm',
  Profile = '/profil',
  UserRoleVerificationList = '/weryfikacja-uzytkownikow',
  UserRoleVerification = '/weryfikacja-uzytkownikow/:userId',
  NotFound = '*',
  // custom paths for forks apps
  Activations = '/aktywacje',
  Subscriptions = '/subskrypcje',
}
