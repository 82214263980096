import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Location } from 'history';

import { AppWrapperProps } from './appWrapper.props';
import { AppContainer, Container } from './appWrapper.styled';
import { useAuth, useConfig, useLogger, useRoutingPermissions } from '@chic/hooks';
import { 
  AttachedVerticalMenu, 
  Breakpoint, 
  ComponentColorTheme, 
  Footer, 
  MenuPositionData, 
  NavigationMenuItem,
  StickyHeader, 
  UseNotifications, 
  UseState,
  useNotifications, 
} from '@chic-loyalty/ui';
import { AxiosInterceptor } from '../axiosInterceptor/axiosInterceptor.component';
import { ConfigsContextType, FooterPosition, MenuPosition, UseAuth, UseLogger, UseRoutingPermissions } from '@chic/models';
import { useMediaQuery } from 'react-responsive';
import { getRouteName } from '@chic/utils';
import { urlParamsConfig } from '@chic/configs';
import { UseSearchParams } from '@chic/types';
import { stringify } from 'query-string';

export const AppWrapper: React.FC<AppWrapperProps> = (props: AppWrapperProps): JSX.Element => {
  const { fullscreenAlertData, children }: AppWrapperProps = props;
  const { isLoggedIn, signOut, authData }: UseAuth = useAuth();
  const { globalErrorHandler }: UseLogger = useLogger();
  const { isMenuPositionAvailable }: UseRoutingPermissions = useRoutingPermissions();
  const location: Location = useLocation();
  const config: ConfigsContextType = useConfig();
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const [isStickyHeaderVisible, setIsStickyHeaderVisible]: UseState<boolean> = useState<boolean>(isTablet);
  const [searchParams, setSearchParams]: UseSearchParams = useSearchParams();
  const { showFullscreenAlert }: UseNotifications = useNotifications();

  useEffect(
    (): void => {
      if (fullscreenAlertData) {
        showFullscreenAlert(fullscreenAlertData);
      }
    },
    [fullscreenAlertData],
  );

  const hideMenu: () => void = (): void => {
    if (isTablet) {
      setIsStickyHeaderVisible(true);
    }
  };

  const paramsPath: string = useMemo(
    (): string => {
      let recordParams: Record<string, string> = {};
      searchParams.forEach((value: string, key: string) => {
        if (urlParamsConfig.includes(key)) {
          recordParams = { ...recordParams, [key]: searchParams.get(key) ?? '' };
        } else {
          searchParams.delete(key);
          setSearchParams(searchParams);
        }
      });
    
      return stringify(recordParams);
    },
    [searchParams],
  );

  const menuPositions: NavigationMenuItem[] = useMemo(
    (): NavigationMenuItem[] => {
      const positions: MenuPosition[] = authData.user?.verified
        ? config.menu.filter(isMenuPositionAvailable)
        : [];
        
      return positions.map((position: MenuPosition): NavigationMenuItem => ({
        internalPath: `${position.pathname}?${paramsPath}`,
        icon: position.inactiveIcon,
        label: position.label,
        onClick: hideMenu,
        name: getRouteName(position.pathname),
      }));
    },
    [authData.user?.verified, config.menu, paramsPath],
  );

  const footerPositions: MenuPositionData[] = useMemo(
    (): MenuPositionData[] => {
      return config.footer.map((position: FooterPosition): MenuPositionData => ({
        label: position.label,
        ...(position.isExternalPath ? { externalUrl: position.path } : { internalPath: position.path }),
      }));
    },
    [config.footer],
  );

  useEffect(
    () : void => window.scrollTo({ top: 0 }),
    [location.pathname],
  );

  useEffect(
    (): void => {
      setIsStickyHeaderVisible(isTablet);
    },
    [isTablet],
  );

  useEffect((): void => globalErrorHandler(), []);

  return (
    <AxiosInterceptor>
      <Container $withFixedHeight={!isStickyHeaderVisible && isTablet}>
        {isLoggedIn && (
          <AttachedVerticalMenu 
            menuPositions={menuPositions} 
            logoActionData={{ internalPath: config.defaultRoute }} 
            activeItemName={getRouteName(location.pathname) ?? ''} 
            logoutAction={(): void => {
              hideMenu();
              signOut();
            }} 
            closeAction={hideMenu} 
            isVisible={!isStickyHeaderVisible}
            withReturnTo={false}
          />
        )}
        <AppContainer $hasPadding={isLoggedIn}> 
          {isLoggedIn && (
            <StickyHeader
              isHeaderVisible={isStickyHeaderVisible}
              logoActionData={{ internalPath: config.defaultRoute }} 
              onHamburgerButtonClick={(): void => setIsStickyHeaderVisible(false)}
              hasUserContext={isLoggedIn}
              withReturnTo={false}
            />
          )}
          {children}
          <Footer menuPositions={footerPositions} colorTheme={ComponentColorTheme.IC} />
        </AppContainer>
      </Container>
    </AxiosInterceptor>
  );
};
