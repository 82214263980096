import { AxiosResponse } from 'axios';
import stringify from 'query-string';

import {
  ApiError,
  ApiReject,
  ApiResolve,
  Employee,
  ListData,
  Store,
  StoreActivation,
  StoreOwner,
  StoreStats,
  StoreSubscription,
} from '@chic/models';
import { api } from '../api.service';

export const getStoreStats: (stores: number[], dateFrom: string, dateTo: string) => Promise<StoreStats> = (
  stores: number[], dateFrom: string, dateTo: string,
): Promise<StoreStats> => new Promise(
  (resolve: ApiResolve<StoreStats>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/franchise/stores/stats?${stringify.stringify({ stores, dateFrom, dateTo }, { 
      skipEmptyString: true, 
      arrayFormat: 'bracket', 
    })}`).then(
      (response: AxiosResponse<StoreStats>): void => { resolve(response?.data); },
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  ),
);

export const getStoreDetails: (storeId: number) => Promise<Store> = (storeId: number): Promise<Store> => new Promise(
  (resolve: ApiResolve<Store>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/franchise/stores/${storeId}/details`).then(
      (response: AxiosResponse<Store>): void => { resolve(response?.data); },
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  ),
);

export const getStoreSubscriptions: (
  stores: number[], dateFrom: string, dateTo: string, plan?: string, sellerId?: number, limit?: number, offset?: number, page?: number
) => Promise<ListData<StoreSubscription>> = (
  stores: number[], dateFrom: string, dateTo: string, plan?: string, sellerId?: number, limit: number = 10, offset?: number, page?: number,
): Promise<ListData<StoreSubscription>> => new Promise(
  (resolve: ApiResolve<ListData<StoreSubscription>>, reject: ApiReject<ApiError>): Promise<void> => (
    api
      .get(`/franchise/stores/subscriptions?${stringify
        .stringify({ stores, plan, sellerId, limit, offset, page, dateFrom, dateTo }, { 
          skipEmptyString: true, 
          arrayFormat: 'bracket',  
        })}`)
      .then(
        (response: AxiosResponse<ListData<StoreSubscription>>): void => { resolve(response?.data); },
      ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  ),
);

export const getStoresOwners: () => Promise<StoreOwner[]> = (): Promise<StoreOwner[]> => new Promise(
  (resolve: ApiResolve<StoreOwner[]>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get('/franchise/stores/owners').then(
      (response: AxiosResponse<StoreOwner[]>): void => { resolve(response?.data); },
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  ),
);

export const getStoresAvailableForLoggedUser: () => Promise<Store[]> = (): Promise<Store[]> => new Promise(
  (resolve: ApiResolve<Store[]>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get('/franchise/stores').then(
      (response: AxiosResponse<Store[]>): void => { resolve(response?.data); },
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  ),
);

export const getStoresByNip: (nip: string) => Promise<Store[]> = (nip: string): Promise<Store[]> => new Promise(
  (resolve: ApiResolve<Store[]>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/franchise/stores/${nip}`).then(
      (response: AxiosResponse<Store[]>): void => { resolve(response?.data); },
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  ),
);

export const getStoreEmployees: (stores: number[]) => Promise<Employee[]> = (stores: number[]): Promise<Employee[]> => new Promise(
  (resolve: ApiResolve<Employee[]>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/franchise/stores/employees?${stringify.stringify({ stores }, { 
      skipEmptyString: true, 
      arrayFormat: 'bracket',  
    })}`).then(
      (response: AxiosResponse<Employee[]>): void => { resolve(response?.data); },
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  ),
);

export const getStoreActivations: (
  stores: number[], 
  dateFrom: string, 
  dateTo: string, 
  promotion?: string, 
  sellerId?: number, 
  limit?: number, 
  offset?: number, 
  page?: number, 
) => Promise<ListData<StoreActivation>> = (
  stores: number[], 
  dateFrom: string, 
  dateTo: string, 
  promotion?: string, 
  sellerId?: number, 
  limit: number = 10, 
  offset?: number, 
  page?: number,
): Promise<ListData<StoreActivation>> => new Promise(
  (resolve: ApiResolve<ListData<StoreActivation>>, reject: ApiReject<ApiError>): Promise<void> => (
    api.get(`/franchise/stores/activations?${stringify.stringify(
      { stores, promotion, sellerId, dateFrom, dateTo, limit, offset, page }, { 
        skipEmptyString: true, 
        arrayFormat: 'bracket',  
      },
    )}`).then(
      (response: AxiosResponse<ListData<StoreActivation>>): void => { resolve(response?.data); },
    ).catch((error: AxiosResponse<ApiError>): void => reject(error))
  ),
);
