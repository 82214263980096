import { Breakpoint, Button, CalcSize, Color, Logo, Markdown, StyledComponent } from '@chic-loyalty/ui';
import { FileFromViews } from '@chic/enums';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: calc(100vh - ${CalcSize.FooterHeight});
  background: url(${FileFromViews.Background});
  background-size: cover;

  @media ${Breakpoint.Mobile} {
    height: auto;
    flex-direction: column;
    background: none;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 48px;
`;

export const LogoBox: StyledComponent<'div'> = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px;

  @media ${Breakpoint.Tablet} {
    padding: 60px;
  }

  @media ${Breakpoint.Mobile} {
    background: url(${FileFromViews.MobileBackground});
    background-size: cover;
    width: 100%;
  }
`;

export const StyledLogo: StyledComponent<typeof Logo> = styled(Logo)`
  max-height: 125px;
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const LoginBox: StyledComponent<'div'> = styled.div`
  width: 50%;
  height: 100%;
  padding: 40px 40px 40px 0;
  display: flex;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    width: 100%;
    padding: 0;
  }
`;

export const InnerBlackBox: StyledComponent<'div'> = styled.div`
  max-width: 688px;
  width: 100%;
  max-height: 690px;
  height: 100%;
  background-color: ${Color.ICBlack500};
  color: ${Color.ICWhite100};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
    height: calc(100vh - 120px);
  }
`;

export const Title: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 34px; 
  color: ${Color.ICWhite100};
  line-height: 32px;
  width: 100%;
  text-align: center;

  @media ${Breakpoint.Mobile} {
    font-size: 28px;  
  }
`;
