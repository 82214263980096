import { Autosuggest, Breakpoint, CalcSize, Color, Grid, MetricBox, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  min-height: calc(100vh - ${CalcSize.FooterHeight} - ${CalcSize.HealthWarningHeight});
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DetailsContainer: StyledComponent<'div'> = styled.div`
  min-height: 302px;
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  padding: 0 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${Color.ICWhite100};
`;

export const ChildrenContainer: StyledComponent<'div'> = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 24px;
`;

export const MetricBoxesContainer: StyledComponent<'div'> = styled.div`
  margin-bottom: 62px;
  width: 100%;
`;

export const MetricBoxes: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const StyledMetricBox: StyledComponent<typeof MetricBox> = styled(MetricBox)`
  width: 100%;
  height: auto;

  & + & {
    margin-left: 12px;
  }

  @media ${Breakpoint.Mobile} {
    & + & {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`;

export const StyledAutosuggest: StyledComponent<typeof Autosuggest> = styled(Autosuggest)`
  width: 100%;
  max-width: ${Grid.ICSmallDesktop};
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 24px 0;
`;

export const PageHeaderContainer: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 0 24px;
`;
