import styled from 'styled-components';
import { CalcSize, Color, Grid, Input, MenuPosition, Pagination, StyledComponent } from '@chic-loyalty/ui';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  background-color: ${Color.ICBlack500};
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 24px 24px 0;
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 96px 24px;
`;

export const FiltersContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  width: 100%;
`;

export const StyledPagination: StyledComponent<typeof Pagination> = styled(Pagination)`
  margin: 25px 0;
`;

export const TableWrapper: StyledComponent<'div'> = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TableChildren: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%; 
  align-items: center;
`;

export const StyledMenuPosition: StyledComponent<typeof MenuPosition> = styled(MenuPosition)`
  display: flex;
  justify-content: center;
`;

export const EmptyListMessage: StyledComponent<'p'> = styled.p`
  color: ${Color.ICWhite100};
  padding: 20px 0;
`;
