import { 
  DropdownOption, 
  FullscreenAlertTheme, 
  UseNotifications, 
  UseRedirect, 
  UseState, 
  useNotifications, 
  useRedirect, 
} from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';
import { getUserRoles, saveUserManagementRole } from '@chic/api';
import { QueryKey, RoleVisibilityFlag, RoutingPath } from '@chic/enums';
import { FrontendApiError, Role, UseRoleVerificationBox } from '@chic/models';
import { useState } from 'react';
import { useQuery } from 'react-query';

export const useRoleVerificationBox: () => UseRoleVerificationBox = (): UseRoleVerificationBox => {
  const [roles, setRoles]: UseState<Role[] | null> = useState<Role[] | null>(null);
  const [selectedRole, setSelectedRole]: UseState<DropdownOption | null> = useState<DropdownOption | null>(null);
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications(); 
  const { redirect }: UseRedirect = useRedirect();
  const { t }: TransProps<never> = useTranslation();

  useQuery(
    [QueryKey.UserRoles],
    (): Promise<Role[]> => getUserRoles(),
    {
      onSuccess: (data: Role[]): void => setRoles(
        data.filter((role: Role): boolean => role.visibilityFlags.includes(RoleVisibilityFlag.AllowVerificationCrm)),
      ),
      onError: (): void => undefined,
    },
  );

  const acceptButtonAction: (userName: string, userId: number, role?: Role, actionAfterRoleChange?: () => void) => void = (
    userName: string, userId: number, role?: Role, actionAfterRoleChange?: () => void,
  ): void => {
    if (!role) {
      return;
    }

    showFullscreenAlert({
      title: t('chic.crmApp.useUserVerification.acceptButton.alert.title'),
      description: t('chic.crmApp.useUserVerification.acceptButton.alert.description', { userName, role: role.label }),
      fullscreenAlertTheme: FullscreenAlertTheme.Success,
      acceptButtonSettings: {
        label: t('chic.crmApp.global.yes'),
        action: (): void => {
          saveUserManagementRole(userId, role.id)
            .then((): void => {
              if (actionAfterRoleChange) {
                actionAfterRoleChange();
              }
              hideFullscreenAlert();
              redirect(RoutingPath.UserRoleVerificationList);
            })
            .catch((error: FrontendApiError): void => {
              showFullscreenAlert({
                title: error.message ?? '',
                fullscreenAlertTheme: FullscreenAlertTheme.Error,
                acceptButtonSettings: {
                  label: t('chic.crmApp.useUserVerification.acceptButton.error.goBack'),
                  action: (): void => {
                    if (actionAfterRoleChange) {
                      actionAfterRoleChange();
                    }
                    hideFullscreenAlert();
                    redirect(RoutingPath.UserRoleVerificationList);
                  }, 
                },
              });
            });
        },
      },
      cancelButtonSettings: {
        label: t('chic.crmApp.global.no'),
        action: (): void => {
          hideFullscreenAlert();
        },
      },
    });
    setSelectedRole(null);
  };

  return { roles, selectedRole, setSelectedRole, acceptButtonAction };
};
