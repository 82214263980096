import { AxiosResponse } from 'axios';

import { ApiReject, ApiResolve, BaseApiData, PasswordChangeData } from '@chic/models';

import { api } from '../api.service';

export const changePassword: (values: PasswordChangeData) => Promise<BaseApiData> = (
  values: PasswordChangeData,
): Promise<BaseApiData> => new Promise(
  (resolve: ApiResolve<BaseApiData>, reject: ApiReject): Promise<void> => (
    api.post('/password/update', {
      oldPassword: values.oldPassword,
      password: values.password,
    }).then((response: AxiosResponse): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const remindPassword: (login: string) => Promise<void> = (login: string): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject): Promise<void> => (
    api.post('/password/remind', { login })
      .then((): void => resolve())
      .catch(reject)
  ),
);

export const resetPassword: (password: string, token: string) => Promise<BaseApiData> = (
  password: string,
  token: string,
): Promise<BaseApiData> => new Promise(
  (resolve: ApiResolve<BaseApiData>, reject: ApiReject): Promise<void> => (
    api.post('/password/reset', { token, password })
      .then((response: AxiosResponse): void => resolve(response?.data))
      .catch(reject)
  ),
);
