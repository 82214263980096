import { 
  Color,
  ComponentColorTheme,
  DropdownOption,
  FranchiseDetailsTable, 
  FranchiseRowTheme, 
  FranchiseTableCellTheme, 
  FranchiseTableCellType, 
  FranchiseTableRow, 
  IconName, 
  PageTitle, 
  PaginationDetails, 
  TextAlign,
  UseState,
  VisibilitySettings,
  usePagination,
} from '@chic-loyalty/ui';
import debounce from 'lodash.debounce';
import { BaseStatsView } from '@chic/components';
import { ListData, Store, StoreSubscription, UseStores } from '@chic/models';
import React, { useCallback, useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { 
  ContentWrapper, 
  InputsContainer, 
  Message, 
  StyledInput, 
  StyledPagination, 
  StyledSmallDropdown, 
} from './subscriptions.styled';
import { getStoreSubscriptions } from '@chic/api';
import { FilterKey, QueryKey } from '@chic/enums';
import { emptyRequest } from '@chic/utils';
import { QueryObserverResult, useQuery } from 'react-query';
import { useStores } from '../../hooks/useStores.hook'; // TODO: find out why index import don't work and crash builds
import { SubscriptionsFranchiseTableName } from './subscriptions.enums';

export const SubscriptionsView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { setMaxItems, pagesCount, page, setPage, itemsPerPage, offset }: PaginationDetails = usePagination(); 
  const { 
    selectedStore, 
    employees, 
    onChooseStoreOwner, 
    stores,
    onChooseStore, 
    onChooseDate, 
    initialStartDate,
    initialEndDate,
    subscriptionsMetricData,
    storesOwners,
    startDate,
    endDate,
    selectedStoreOwner,
  }: UseStores = useStores();
  const [subscriptionsFranchiseTable, setSubscriptionsFranchiseTable]: UseState<FranchiseTableRow[] | null> 
    = useState<FranchiseTableRow[] | null>(null);
  const [planName, setPlanName]: UseState<string> = useState<string>('');
  const [selectedEmployee, setSelectedEmployee]: UseState<string> = useState<string>('');

  useEffect(
    (): void => {
      if (planName.length) {
        setPlanName('');
      }
      if (selectedEmployee.length) {
        setSelectedEmployee('');
      }
    },
    [selectedStore, selectedStoreOwner],
  );

  const onPlanNameChange: (value: string) => void = (value: string): void => {
    setPlanName(value);
  };

  const onDebouncedPlanNameChange: (value: string) => void = useCallback(debounce(onPlanNameChange, 500), []);

  const { isFetched }: QueryObserverResult = useQuery(
    [QueryKey.StoreSubscriptions, selectedStore, stores, planName, selectedEmployee, page, startDate, endDate],
    (): Promise<ListData<StoreSubscription> | void> => selectedStore
      ? getStoreSubscriptions(
        selectedStore === FilterKey.All
          ? stores.map((store: Store): number => store.storeId)
          : [selectedStore.storeId],  
        startDate ?? initialStartDate,
        endDate ?? initialEndDate,
        planName, 
        selectedEmployee && selectedEmployee !== 'all' ? Number(selectedEmployee) : undefined,
        itemsPerPage,
        offset,
        page,
      )
      : emptyRequest(),
    {
      onSuccess: (data: ListData<StoreSubscription> | void): void => {
        if (!data) {
          setSubscriptionsFranchiseTable([]);
          return;
        }
        
        setMaxItems(data.count);
        setSubscriptionsFranchiseTable([
          ...data.elements.map((element: StoreSubscription): FranchiseTableRow => ({
            theme: element.active ? FranchiseRowTheme.Normal : FranchiseRowTheme.Disabled,
            cells: [
              {
                name: SubscriptionsFranchiseTableName.CreateDatetime,
                value: String(new Date(element.createDatetime).getTime()),
                cellTheme: FranchiseTableCellTheme.ICWhite,
                cellType: FranchiseTableCellType.Date,
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: SubscriptionsFranchiseTableName.CustomerEmail,
                value: element.customer?.email ?? '-',
                cellTheme: FranchiseTableCellTheme.ICWhite,
                cellType: FranchiseTableCellType.Text,
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: SubscriptionsFranchiseTableName.SellerName,
                value: element.seller?.name ?? '-',
                cellTheme: FranchiseTableCellTheme.ICWhite,
                cellType: FranchiseTableCellType.Text,
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: SubscriptionsFranchiseTableName.PlanName,
                value: element.plan.name,
                cellTheme: FranchiseTableCellTheme.ICWhite,
                cellType: FranchiseTableCellType.SubscriptionPlan,
                visibilitySettings: VisibilitySettings.Always,
              },
              {
                name: SubscriptionsFranchiseTableName.Orders,
                value: String(element.orders),
                cellTheme: FranchiseTableCellTheme.ICWhite,
                cellType: FranchiseTableCellType.Text,
                visibilitySettings: VisibilitySettings.Always,
                leftLabel: !element.active ? t('chic.franchisees.subscriptionsView.subscriptionsFranchiseTable.active.false') : undefined,
                textAlign: TextAlign.Right,
                icon: IconName.Box,
                iconColor: Color.ICYellow100,
              },
            ],
          })),
        ]);
      },
      // TODO: add logger
      onError: (): void => undefined,
    },
  );
  
  return (
    <BaseStatsView 
      headerTitle={t('chic.franchisees.subscriptionsView.headerTitle')} 
      stores={stores}
      selectedStore={selectedStore}
      onChooseStoreOwner={onChooseStoreOwner}
      onChooseStore={onChooseStore}
      onChooseDate={onChooseDate}
      initialStartDate={initialStartDate}
      initialEndDate={initialEndDate}
      metricData={subscriptionsMetricData}
      storesOwners={storesOwners}
    >
      <ContentWrapper>
        <PageTitle label={t('chic.franchisees.subscriptionsView.contentWrapper.title')} />
        <InputsContainer>
          <StyledInput 
            placeholder={t('chic.franchisees.subscriptionsView.contentWrapper.input.placeholder')}
            colorTheme={ComponentColorTheme.IC}
            onChange={onDebouncedPlanNameChange}
            icon={IconName.Search}
            value={planName}
          />
          {!!employees.length && (
            <StyledSmallDropdown
              options={employees}
              initialValue={
                !selectedEmployee 
                  ? employees.find((employee: DropdownOption): boolean => employee.name === 'all')
                  : employees.find((employee: DropdownOption): boolean => employee.name === selectedEmployee)
              }
              onChooseAnswer={(option: DropdownOption): void => setSelectedEmployee(option.name)} 
              label={t('chic.franchisees.subscriptionsView.smallDropdown.label')}
              colorTheme={ComponentColorTheme.IC}
            />
          )}
        </InputsContainer>
        {subscriptionsFranchiseTable !== null && isFetched && (
          subscriptionsFranchiseTable.length && !!selectedStore ? (
            <>
              <FranchiseDetailsTable 
                headers={[
                  {
                    name: SubscriptionsFranchiseTableName.CreateDatetime,
                    label: t('chic.franchisees.subscriptionsView.franchiseDetailsTable.headers.createDatetime'),
                  }, {
                    name: SubscriptionsFranchiseTableName.CustomerEmail,
                    label: t('chic.franchisees.subscriptionsView.franchiseDetailsTable.headers.customerEmail'),
                  }, {
                    name: SubscriptionsFranchiseTableName.SellerName,
                    label: t('chic.franchisees.subscriptionsView.franchiseDetailsTable.headers.sellerName'),
                  }, {
                    name: SubscriptionsFranchiseTableName.PlanName,
                    label: t('chic.franchisees.subscriptionsView.franchiseDetailsTable.headers.planName'),
                  }, {
                    name: SubscriptionsFranchiseTableName.Orders,
                    label: t('chic.franchisees.subscriptionsView.franchiseDetailsTable.headers.orders'),
                    textAlign: TextAlign.Right,
                  },
                ]} 
                tableData={subscriptionsFranchiseTable}
                columnsVisibleOnMobile={[SubscriptionsFranchiseTableName.CreateDatetime, SubscriptionsFranchiseTableName.Orders]} 
                colorTheme={ComponentColorTheme.IC}
              />
              <StyledPagination 
                activePage={page} 
                pagesCount={pagesCount} 
                onActivePageChange={setPage} 
                colorTheme={ComponentColorTheme.IC} 
              />
            </>
          ) : (
            <Message>{t('chic.franchisees.subscriptionsView.emptyTable.message')}</Message>
          )
        )}
      </ContentWrapper>
    </BaseStatsView>
  );
};
