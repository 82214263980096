import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { appConfig } from '../../appConfig.config';

import { Container, Message } from './crmSignInRedirect.styled';
import { Color, ComponentColorTheme, Loader } from '@chic-loyalty/ui';

export const CrmSignInRedirectView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();

  useEffect(
    (): void => {
      window.location.href = `${appConfig.api}/crm/connect`;
    },
    [],
  );

  return (
    <Container>
      <Loader colorTheme={ComponentColorTheme.IC} color={Color.ICYellow100} size={36} />
      <Message>{t('chic.crmApp.crmSignInRedirectView.message')}</Message>
    </Container>
  );
};
