import { Breakpoint, CalcSize, Color, Input, Logo, Markdown, StyledComponent, ValidationBar } from '@chic-loyalty/ui';
import { FileFromViews } from '@chic/enums';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  background: url(${FileFromViews.Background});
  background-size: cover;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    background: none;
    min-height: 0;
  }
`;

export const LogoBox: StyledComponent<'div'> = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px;

  @media ${Breakpoint.Tablet} {
    padding: 60px;
  }

  @media ${Breakpoint.Mobile} {
    background: url(${FileFromViews.MobileBackground});
    background-size: cover;
    width: 100%;
    height: 152px;
  }
`;

export const LoginBox: StyledComponent<'div'> = styled.div`
  width: 50%;
  height: 100%;
  padding: 40px 40px 40px 0;

  @media ${Breakpoint.Mobile} {
    width: 100%;
    padding: 0;
    min-height: 490px;
    height: 100%;
  }
`;

export const InnerBlackBox: StyledComponent<'div'> = styled.div`
  max-width: 688px;
  width: 100%;
  max-height: 690px;
  height: 100%;
  background-color: ${Color.ICBlack500};
  color: ${Color.ICWhite100};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
    min-height: 100%;
  }
`;

export const StyledLogo: StyledComponent<typeof Logo> = styled(Logo)`
  max-height: 125px;
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin-top: 20px;
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  & + & {
    margin-top: 8px;
  }
`;

export const StyledLink: StyledComponent<typeof Link> = styled(Link)`
  margin-top: 20px;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  color: ${Color.ICWhite100};
`;

export const RemindPasswordWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  max-width: 448px;
  text-align: right;
  margin-top: 20px;

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
  }
`;

export const ButtonWrapper: StyledComponent<'div'> = styled.div`
  padding: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(189deg, ${Color.ICBlack100} -36.36%, ${Color.ICBlack500} 126.99%); 
`;

export const FormContainer: StyledComponent<'form'> = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const InnerForm: StyledComponent<'div'> = styled.div`
  padding: 48px 120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;

  @media ${Breakpoint.Tablet} {
    padding: 48px;
  }
`;

export const Title: StyledComponent<typeof Markdown> = styled(Markdown)`
  margin: 131px 0 48px;
  font-size: 34px; 
  color: ${Color.ICWhite100};
  line-height: 32px;
  width: 100%;
  text-align: center;

  @media ${Breakpoint.Mobile} {
    font-size: 28px;  
  }
`;
