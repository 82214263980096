import styled from 'styled-components';
import { Breakpoint, CalcSize, Color, Grid, PageTitle, StyledComponent } from '@chic-loyalty/ui';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  background-color: ${Color.ICBlack500};
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 24px 24px 0;
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 96px 24px;

  @media ${Breakpoint.Mobile} {
    padding: 50px 24px;
  }
`;

export const LoaderWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  margin: 40px 0;
`;
