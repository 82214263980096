import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { PasswordRegexp } from '@chic/constans';
import { PasswordChangeFormData } from './passwordChange.types';

export const usePasswordChangeValidation: () => Yup.Schema<PasswordChangeFormData> = (): Yup.Schema<PasswordChangeFormData> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    oldPassword: Yup.string()
      .required(t('chic.crmApp.global.requiredField')),
    newPasswordFirst: Yup.string()
      .required(t('chic.crmApp.global.requiredField'))
      .matches(PasswordRegexp, t('chic.crmApp.global.validations.passwordFormat'))
      .oneOf([Yup.ref('newPasswordSecond')], t('chic.crmApp.global.validations.passwordsMustMatch'))
      .notOneOf([Yup.ref('oldPassword')], t('chic.crmApp.global.validations.passwordsMustBeDifferentFromOld')),
    newPasswordSecond: Yup.string()
      .required(t('chic.crmApp.global.requiredField'))
      .matches(PasswordRegexp, t('chic.crmApp.global.validations.passwordFormat'))
      .oneOf([Yup.ref('newPasswordFirst')], t('chic.crmApp.global.validations.passwordsMustMatch'))
      .notOneOf([Yup.ref('oldPassword')], t('chic.crmApp.global.validations.passwordsMustBeDifferentFromOld')),
  });
};
