import React from 'react';
import { useAuth, useTitle } from '@chic/hooks';
import { UseAuth } from '@chic/models';

import { Container, PageTitleDescription, PageTitleWrapper } from './dashboard.styled';
import { TransProps, useTranslation } from 'react-i18next';
import { DashboardContent, NoRoleBox } from './components';
import { PageTitle, PageTitleSize } from '@chic-loyalty/ui';

export const DashboardView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  useTitle(t('chic.franchisees.dashboardView.title'));
  const { authData }: UseAuth = useAuth();

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle label={t('chic.franchisees.dashboardView.pageTitle')} size={PageTitleSize.Big}>
          <PageTitleDescription>{authData.user?.name}</PageTitleDescription>
        </PageTitle>
      </PageTitleWrapper>
      <NoRoleBox>
        <DashboardContent />
      </NoRoleBox>
    </Container>
  );
};
