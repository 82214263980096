import { 
  ActionBox, 
  Autosuggest, 
  Breakpoint, 
  Color, 
  Grid, 
  MonthSummary, 
  PosDetails, 
  StyledComponent, 
} from '@chic-loyalty/ui';
import styled from 'styled-components';

export const BoxesContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  max-width: ${Grid.ICSmallDesktop};
  display: flex;
  color: ${Color.ICBlack100};

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const DataBasesContainer: StyledComponent<'div'> = styled.div`
  margin: 0 24px 62px;
  width: calc(100% - 48px);
  max-width: ${Grid.ICSmallDesktop};
`;

export const DetailsContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 24px 24px 62px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${Color.ICYellow100};
`;

export const StyledActionBox: StyledComponent<typeof ActionBox> = styled(ActionBox)`
  margin-top: 25px;

  & + & {
    margin-top: 10px;
  }
`;

export const StyledAutosuggest: StyledComponent<typeof Autosuggest> = styled(Autosuggest)`
  width: 100%;
  max-width: ${Grid.ICSmallDesktop};
`;

export const StyledMonthSummary: StyledComponent<typeof MonthSummary> = styled(MonthSummary)`
  width: 50%;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const StyledPosDetails: StyledComponent<typeof PosDetails> = styled(PosDetails)`
  width: 50%;
  margin-right: 15px;

  @media ${Breakpoint.Mobile} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
`;
