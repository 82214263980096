import { AxiosError, AxiosResponse } from 'axios';
import { MutableRefObject, useRef, useEffect } from 'react';
import { UseNotifications, useNotifications } from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';

import { getUserProfile, api, getUserTokenInfo } from '@chic/api';
import { LocalStorageKey, PopupId, RoutingPath } from '@chic/enums';
import { useAuth } from '@chic/hooks';
import { UseAuth, TokenWithServerDate, BaseApiError, FrontendApiError } from '@chic/models';
import { AxiosInterceptorProps } from './axiosInterceptor.types';
import { userTokenInfoEndpoint } from '@chic/constans';

import PopupIcon from './images/popupIcon.png';

export const AxiosInterceptor: React.FC<AxiosInterceptorProps> = (props: AxiosInterceptorProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { children }: AxiosInterceptorProps = props;
  const { addToast, showPopup, hidePopup }: UseNotifications = useNotifications();
  const { isLoggedIn, signOut }: UseAuth = useAuth();
  const trackSessionTimeout: MutableRefObject<NodeJS.Timeout | null> = useRef<NodeJS.Timeout | null>(null);
  const endSessionTimeout: MutableRefObject<NodeJS.Timeout | null> = useRef<NodeJS.Timeout | null>(null);
  const isSessionTracked: MutableRefObject<boolean | null> = useRef<boolean | null>(false);

  const endSession: () => void = (): void => {
    hidePopup({ id: PopupId.EndSessionPopup });
    signOut();
    addToast({ content: t('chic.crmApp.global.automaticLogoutToast') });
  };

  const handleUserSession: () => void = (): void => {
    getUserTokenInfo()
      .then((data: TokenWithServerDate): void => {
        const tokenValidityTimestamp: number = new Date(data.deactivateAt ?? '').getTime();
        const serverDateTimestamp: number = new Date(data.serverDate).getTime();
        const isSessionLongerThanTwoMinutes: boolean = tokenValidityTimestamp - serverDateTimestamp > 2 * 60 * 1000;
        if (isSessionLongerThanTwoMinutes) {
          return;
        }
        const numberOfMiliSecondsToEndOfSessionMinusTenSeconds: number = tokenValidityTimestamp - serverDateTimestamp - (10 * 1000);
        endSessionTimeout.current = setTimeout(endSession, numberOfMiliSecondsToEndOfSessionMinusTenSeconds);
        showPopup({
          id: PopupId.EndSessionPopup,
          descriptionHeader: t('chic.crmApp.axiosInterceptor.endSessionPopup.descriptionHeader'),
          description: t('chic.crmApp.axiosInterceptor.endSessionPopup.description'),
          image: PopupIcon,
          acceptButtonSettings: {
            label: t('chic.crmApp.axiosInterceptor.endSessionPopup.acceptButtonLabel'),
            action: (): void => {
              if (endSessionTimeout.current) {
                clearTimeout(endSessionTimeout.current);
              }
              getUserProfile();
              hidePopup({ id: PopupId.EndSessionPopup });
            },
          },
          cancelButtonSettings: {
            label: t('chic.crmApp.global.logout'),
            action: (): void => {
              hidePopup({ id: PopupId.EndSessionPopup });
              endSession();
            },
          },
        });
      })
      .catch((): void => {
        signOut();
      });
  };

  useEffect(
    (): (() => void) => {
      const responseInterceptor: (response: AxiosResponse) => AxiosResponse = (response: AxiosResponse): AxiosResponse => {
        const tokenValidityDate: string | undefined = response.headers['x-auth-expires'];
        const serverDate: string | undefined = response.headers['x-server-date'];

        const tokenValidityTimestamp: number = new Date(tokenValidityDate ?? '').getTime();
        const serverDateTimestamp: number = new Date(serverDate ?? '').getTime();
        if (isNaN(tokenValidityTimestamp) || isNaN(serverDateTimestamp)) {
          return response;
        }

        const isTokenStillValid: boolean = tokenValidityTimestamp > serverDateTimestamp;
        if (
          (isTokenStillValid && response.config.url !== userTokenInfoEndpoint)
          || (isTokenStillValid && response.config.url === userTokenInfoEndpoint && !trackSessionTimeout.current)
        ) {
          if (trackSessionTimeout.current) {
            clearTimeout(trackSessionTimeout.current);
          }
          trackSessionTimeout.current = setTimeout(handleUserSession, tokenValidityTimestamp - serverDateTimestamp - (60 * 1000));
          if (!isSessionTracked.current) {
            isSessionTracked.current = true;
          }
        }

        if (response.config.url === userTokenInfoEndpoint) {
          return {
            ...response,
            data: {
              ...response.data,
              serverDate,
            } as TokenWithServerDate,
          };
        }

        return response;
      };

      const errorInterceptor: (error: AxiosError<FrontendApiError>) => void | FrontendApiError = (
        error: AxiosError<FrontendApiError>,
      ): void | FrontendApiError => {
        if (error.response) {
          const apiErrorObject: FrontendApiError<Record<string, unknown> | BaseApiError> = new FrontendApiError(
            error.response.data.success,
            error.response.data.message,
            error.response.data.messageParams,
            error.response.data.data ?? {},
            error.response.data.errors ?? {},
            error.response.status,
            error.response.data.timestamp,
          );
      
          if (error.response.status === 401) {
            if (window.location.pathname !== RoutingPath.SignIn && window.location.pathname !== RoutingPath.CrmSignIn) {
              window.localStorage.setItem(LocalStorageKey.LoggedUserData, '');
              window.localStorage.setItem(LocalStorageKey.LoggedUserToken, '');
              window.location.assign('/');
            }
            throw apiErrorObject;
          } else if (error.response.status === 400 || error.response.status > 401) {
            throw apiErrorObject;
          }
        } else if (error.request && !error.code) {
          throw new FrontendApiError(
            false,
            t('chic.crmApp.errors.custom.noInternetConnection'),
            {},
            {},
            {},
            503,
            undefined,
          );
        } else {
          throw new FrontendApiError(
            false,
            t('chic.crmApp.errors.custom.unknownApiError'),
            {},
            {},
            {},
            503,
            undefined,
          );
        }
      };

      const interceptor: number = api.interceptors.response.use(responseInterceptor, errorInterceptor);

      return (): void => api.interceptors.response.eject(interceptor);
    },
    [],
  );

  // if within 5 seconds there was no communication with the api => CHECK TOKEN
  useEffect(
    (): void => {
      if (!isLoggedIn) {
        return;
      }

      setTimeout(
        (): void => {
          if (!isSessionTracked.current) {
            getUserTokenInfo();
          }
        },
        5000,
      );
    },
    [isLoggedIn],
  );

  return <>{children}</>;
};
