import styled from 'styled-components';

import { PageTitle, StyledComponent } from '@chic-loyalty/ui';

export const Container: StyledComponent<'div'> = styled.div`
  padding: 96px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  margin-bottom: 40px;
`;

export const ButtonBox: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
`;

export const InputsWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
