import { Color, PageTitle, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  padding: 96px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  margin-bottom: 40px;
`;

export const InputsWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 62px;
`;

export const CompanyBox: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyData: StyledComponent<'div'> = styled.div`
  font-size: 14px; 
  color: ${Color.ICWhite100};
  line-height: 24px;
`;
