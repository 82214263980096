import { Breakpoint, Color, FontWeight, Grid, Input, Pagination, SmallDropdown, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  max-width: ${Grid.ICSmallDesktop};
  flex: 1;
  width: 100%;
  color: ${Color.ICWhite100};
`;

export const Message: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Medium};
  font-size: 14px;
  line-height: 20px;
  margin: 40px 0;
`;

export const InputsContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const StyledPagination: StyledComponent<typeof Pagination> = styled(Pagination)`
  margin: 25px 0;
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  max-width: 400px;
  width: 100%;

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
    margin-bottom: 12px;
  }
`;

export const StyledSmallDropdown: StyledComponent<typeof SmallDropdown> = styled(SmallDropdown)`
  max-width: 322px;
  width: 100%;
  margin-left: 20px;

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }
`;
