import { Dispatch } from 'react';

import { AppStateAction } from '@chic/enums';
import { AppStateActions } from '@chic/models';

export const setStateToInitialAction: (dispatch: Dispatch<AppStateActions>) => void = (
  dispatch: Dispatch<AppStateActions>,
): void => {
  dispatch({ type: AppStateAction.SetStateToInitial, payload: null });
};
