import { useContext } from 'react';

import { AuthContext } from '@chic/contexts';
import { AuthStateAction, LocalStorageKey, RoutingPath, UserRoleName } from '@chic/enums';
import { AuthContextType, AuthState, UseAuth, ConfigsContextType } from '@chic/models';
import { logout, setAuthToken } from '@chic/api';
import { useAppState, useConfig } from '@chic/hooks';
import { setStateToInitialAction } from '@chic/reducers';
import { UseLocalStorage, UseRedirect, useLocalStorage, useRedirect } from '@chic-loyalty/ui';

export const useAuth: () => UseAuth = (): UseAuth => {
  const { redirect }: UseRedirect = useRedirect();
  const config: ConfigsContextType = useConfig();
  const [authData, dispatch]: AuthContextType = useContext(AuthContext);
  const [, appStateDispatch] = useAppState();
  const [, storeAuthData]: UseLocalStorage<string | null> = useLocalStorage<string | null>(LocalStorageKey.LoggedUserData, '');
  const [, storeAuthToken]: UseLocalStorage<string | null> = useLocalStorage<string | null>(LocalStorageKey.LoggedUserToken, '');

  const signIn: (data: AuthState) => void = (data: AuthState): void => {
    setAuthToken(data.token?.value || '');
    dispatch({ type: AuthStateAction.SignIn, payload: data });
    storeAuthData(JSON.stringify(data));
    storeAuthToken(data.token?.value || '');
    redirect(!data.user?.verified
      ? RoutingPath.Dashboard
      : config.defaultRoute,
    );
  };

  const signOut: () => void = (): void => {
    logout()
      .then((): void => undefined)
      .catch((): void => undefined);
    setAuthToken('');
    dispatch({ type: AuthStateAction.SignOut, payload: null });
    setStateToInitialAction(appStateDispatch);
    storeAuthData('');
    storeAuthToken('');
  };

  const isAdminLoggedIn: () => boolean = (): boolean => {
    return authData.user?.role?.name
      ? [UserRoleName.SuperAdmin, UserRoleName.Admin].includes(authData.user.role.name as UserRoleName)
      : false;
  };

  return {
    authData,
    signIn,
    signOut,
    isAdminLoggedIn,
    isLoggedIn: !!authData.token,
  };
};
