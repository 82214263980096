import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { PasswordRegexp } from '@chic/constans';
import { ResetPasswordData } from '@chic/models';

export const usePasswordChangeValidation: () => Yup.Schema<ResetPasswordData> = (): Yup.Schema<ResetPasswordData> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    password: Yup.string()
      .required(t('chic.crmApp.global.requiredField'))
      .matches(PasswordRegexp, t('chic.crmApp.global.validations.passwordFormat'))
      .oneOf([Yup.ref('repeatedPassword')], t('chic.crmApp.global.validations.passwordsMustMatch')),
    repeatedPassword: Yup.string()
      .required(t('chic.crmApp.global.requiredField'))
      .matches(PasswordRegexp, t('chic.crmApp.global.validations.passwordFormat'))
      .oneOf([Yup.ref('password')], t('chic.crmApp.global.validations.passwordsMustMatch')),
  });
};
