import { BaseApiError } from './baseApiError.model';
import { ErrorMessage } from './errorMessage.model';

export class FrontendApiError<ApiErrorData extends BaseApiError | Record<string, unknown> = Record<string, unknown>> {
  public success: boolean;

  public message: string | null;

  public messageParams: Record<string, unknown> | null;
  
  public data: ApiErrorData;
  
  public errors: Record<string, ErrorMessage>;
  
  public responseCode: number;

  public timestamp?: number;

  constructor(
    success: boolean,
    message: string | null,
    messageParams: Record<string, unknown> | null,
    data: ApiErrorData,
    errors: Record<string, ErrorMessage>,
    responseCode: number,
    timestamp: number | undefined,
  ) {
    this.success = success;
    this.message = message;
    this.messageParams = messageParams;
    this.timestamp = timestamp;
    this.data = data;
    this.errors = errors ?? {};
    this.responseCode = responseCode;
  }
}
