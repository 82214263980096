import { 
  SuggestionItem, 
  IconName, 
  SummaryRecord, 
  UseState, 
  UseFormatDate, 
  useFormatDate, 
  MetricBoxSettings,
  DropdownOption, 
} from '@chic-loyalty/ui';
import { getStoresOwners, getStoresByNip, getStoreStats, getStoreEmployees, getPromotionsList } from '@chic/api';
import { FilterKey, QueryKey, UrlParams } from '@chic/enums';
import { StoreOwner, Store, StoreStats, UseAuth, UseStores, Employee, StorePromotion } from '@chic/models';
import { emptyRequest } from '@chic/utils';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from './useAuth.hook';
import { TransProps, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { UseSearchParams } from '@chic/types';

export const useStores: () => UseStores = (): UseStores => {
  const { t }: TransProps<never> = useTranslation();
  const { isAdminLoggedIn, authData }: UseAuth = useAuth();
  const { formatDate }: UseFormatDate = useFormatDate();
  const [searchParams, setSearchParams]: UseSearchParams = useSearchParams();
  const [stores, setStores]: UseState<Store[]> = useState<Store[]>([]);
  const [storesOwners, setStoresOwners]: UseState<SuggestionItem[]> = useState<SuggestionItem[]>([]);
  const [selectedStore, setSelectedStore]: UseState<Store | FilterKey | null | undefined> 
    = useState<Store | FilterKey | null | undefined>(undefined);
  const [storeStats, setStoreStats]: UseState<SummaryRecord[]> = useState<SummaryRecord[]>([]);
  const [selectedStoreOwner, setSelectedStoreOwner]: UseState<SuggestionItem | null> = useState<SuggestionItem | null>(null);
  const [subscriptionsMetricData, setSubscriptionsMetricData]: UseState<MetricBoxSettings[]> = useState<MetricBoxSettings[]>([]);
  const [activationsMetricData, setActivationsMetricData]: UseState<MetricBoxSettings[]> = useState<MetricBoxSettings[]>([]);
  const [startDate, setStartDate]: UseState<string> = useState<string>('');
  const [endDate, setEndDate]: UseState<string> = useState<string>('');
  const [employees, setEmployees]: UseState<DropdownOption[]> = useState<DropdownOption[]>([]);
  const [promotionsList, setPromotionsList]: UseState<StorePromotion[]> = useState<StorePromotion[]>([]);
  const date: Date = new Date();
  const isAdminLoggedInApp: boolean = useMemo((): boolean => isAdminLoggedIn(), [authData]);
  const initialStartDate: number = useMemo((): number => new Date().setMonth(date.getMonth() - 11), []);
  const initialEndDate: number = useMemo((): number => new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime(), []);

  const onChooseStore: (store: Store | FilterKey | null) => void = (store: Store | FilterKey | null): void => {
    setSelectedStore(store);
    if (store) {
      if (store === FilterKey.All) {
        searchParams.set(UrlParams.Store, FilterKey.All);
      } else {
        searchParams.set(UrlParams.Store, String(store.storeId));
      }
      setSearchParams(searchParams);
    } else {
      searchParams.delete(UrlParams.Store);
      setSearchParams(searchParams);
    }
  };

  const onChooseStoreOwner: (item: SuggestionItem | null) => void = (item: SuggestionItem | null): void => {
    setSelectedStoreOwner(item);
    if (item) {
      searchParams.set(UrlParams.Owner, item.id);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(UrlParams.Owner);
      setSearchParams(searchParams);
    }
  };

  useQuery(
    [QueryKey.StoreOwners],
    (): Promise<StoreOwner[]> => getStoresOwners(),
    {
      enabled: isAdminLoggedInApp,
      onSuccess: (data: StoreOwner[]): void => {
        const ownerFromParams: StoreOwner | undefined = data
          .find((storeOwner: StoreOwner): boolean => storeOwner.nip === searchParams.get(UrlParams.Owner));

        setStoresOwners(data
          .map((owner: StoreOwner): SuggestionItem => ({
            id: owner.nip,
            label: owner.name,
          })),
        );

        if (data.length) {
          onChooseStoreOwner({
            id: ownerFromParams?.nip ?? data[0].nip,
            label: ownerFromParams?.name ?? data[0].name,
          });
        }
      },
      // TODO: add logger
      onError: (): void => undefined,
    },
  );

  useQuery(
    [QueryKey.StoresByNip, selectedStoreOwner],
    (): Promise<Store[] | void> => !isAdminLoggedInApp
      ? authData.user?.nip
        ? getStoresByNip(authData.user.nip)
        : emptyRequest()
      : selectedStoreOwner?.id
        ? getStoresByNip(selectedStoreOwner.id)
        : emptyRequest(),
    {
      onSuccess: (data: Store[] | void): void => {
        if (!data) {
          return;
        }
        const storeFromParams: Store | FilterKey | undefined = searchParams.get(UrlParams.Store) !== FilterKey.All 
          ? data.find((store: Store): boolean => String(store.storeId) === searchParams.get(UrlParams.Store)) 
          : FilterKey.All;

        setStores(data);

        if (data.length) {
          onChooseStore(storeFromParams ?? data[0]);
        } else {
          onChooseStore(null);
        }
      },
      // TODO: add logger
      onError: (): void => undefined,
    },
  );

  useQuery(
    [QueryKey.PromotionsList],
    (): Promise<StorePromotion[]> => getPromotionsList(),
    {
      onSuccess: (data: StorePromotion[]): void => setPromotionsList(data),
      // TODO: add logger
      onError: (): void => undefined,
    },
  );

  useQuery(
    [QueryKey.StoreStats, selectedStore, stores, startDate, endDate],
    (): Promise<StoreStats | void> => (selectedStore === FilterKey.All || selectedStore?.storeId) && startDate && endDate
      ? getStoreStats(
        selectedStore === FilterKey.All
          ? stores.map((store: Store): number => store.storeId)
          : [selectedStore.storeId], 
        startDate, 
        endDate,
      )
      : emptyRequest(),
    {
      onSuccess: (data: StoreStats | void): void => {
        if (!data) {
          return;
        }
        
        setStoreStats([
          {
            icon: IconName.PaymentCard,
            value: data.registeredUsers,
            label: t('chic.franchisees.useStores.storeStats.registeredUsers.label'),
            tooltipContent: t('chic.franchisees.useStores.storeStats.registeredUsers.tooltipContent'),
          },
          {
            icon: IconName.Box,
            value: data.createdSubscriptions,
            label: t('chic.franchisees.useStores.storeStats.createdSubscriptions.label'),
            tooltipContent: t('chic.franchisees.useStores.storeStats.createdSubscriptions.tooltipContent'),
          },
          {
            icon: IconName.Box,
            value: data.usedPromotions,
            label: t('chic.franchisees.useStores.storeStats.usedPromotions.label'),
            tooltipContent: t('chic.franchisees.useStores.storeStats.usedPromotions.tooltipContent'),
          },
        ]);

        setActivationsMetricData([
          {
            label: t('chic.franchisees.useStores.activationsMetricData.activeCustomers'),
            value: data.activeCustomers,
            icon: IconName.Clients,
          },
          {
            label: t('chic.franchisees.useStores.activationsMetricData.usedPromotions'),
            value: data.usedPromotions,
            icon: IconName.Activation,
          },
          {
            label: t('chic.franchisees.useStores.activationsMetricData.promotionsListLength'),
            value: promotionsList.length,
            icon: IconName.Box,
          },
        ]);

        setSubscriptionsMetricData([
          {
            label: t('chic.franchisees.useStores.subscriptionsMetricData.registeredUsers'),
            value: data.registeredUsers,
            icon: IconName.Clients,
          },
          {
            label: t('chic.franchisees.useStores.subscriptionsMetricData.createdSubscriptions'),
            value: data.createdSubscriptions,
            icon: IconName.Subscription,
          },
          {
            label: t('chic.franchisees.useStores.subscriptionsMetricData.paidSubscriptionsOrders'),
            value: data.paidSubscriptionsOrders,
            icon: IconName.Box,
          },
        ]);
      },
      // TODO: add logger
      onError: (): void => undefined,
    },
  );

  useQuery(
    [QueryKey.StoreEmployees, selectedStore],
    (): Promise<Employee[] | void> => (selectedStore === FilterKey.All || selectedStore?.storeId)
      ? getStoreEmployees(selectedStore === FilterKey.All
        ? stores.map((store: Store): number => store.storeId)
        : [selectedStore.storeId],
      )
      : emptyRequest(),
    {
      onSuccess: (data: Employee[] | void): void => {
        if (!data) {
          return;
        }

        setEmployees([
          { name: 'all', label: t('chic.franchisees.global.employees.all') },
          ...data
            .map((employee: Employee): DropdownOption => ({ name: String(employee.id), label: employee.name })),
        ]);
      },
    },
  );

  const onChooseDate: (start: number, end?: number) => void = (start: number, end?: number): void => {
    if (!start || !end) {
      return;
    }

    setStartDate(formatDate(start, 'YYYY-MM-DD'));
    setEndDate(formatDate(end, 'YYYY-MM-DD'));
  };

  useEffect(
    (): void => {
      if (!startDate && !endDate) {
        onChooseDate(initialStartDate, initialEndDate);
      }
    }, 
    [initialStartDate, initialEndDate, onChooseDate],
  );

  return {
    storesOwners,
    storeStats,
    stores,
    selectedStore,
    selectedStoreOwner,
    onChooseStore,
    onChooseStoreOwner,
    subscriptionsMetricData,
    onChooseDate,
    initialStartDate,
    initialEndDate,
    employees,
    activationsMetricData,
    startDate,
    endDate,
  };
};
