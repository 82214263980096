import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Location } from 'history';
import { Form, Formik, FormikProps } from 'formik';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { RoutingPath, ErrorMessageType } from '@chic/enums';
import { ResetPasswordData, FrontendApiError } from '@chic/models';
import { resetPassword } from '@chic/api';

import { Container, Description, InnerContainer, PageTitleWrapper, StyledButton, StyledInput } from './changePassword.styled';
import { 
  ButtonTheme, 
  ComponentColorTheme, 
  FullscreenAlertTheme, 
  InputTheme, 
  PageTitle, 
  PageTitleSize, 
  UseNotifications, 
  UseRedirect, 
  UseState, 
  useNotifications,
  useRedirect, 
} from '@chic-loyalty/ui';
import { usePasswordChangeValidation } from './changePassword.hooks';

export const ChangePasswordView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { addToast, showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { redirect }: UseRedirect = useRedirect();
  const [isButtonLoading, setIsButtonLoading]: UseState<boolean> = useState<boolean>(false);
  const location: Location = useLocation();
  const params: URLSearchParams = new URLSearchParams(location.search);
  const token: string | null = params.get('t');
  const ChangePasswordValidationSchema: Yup.Schema<ResetPasswordData> = usePasswordChangeValidation();

  useEffect(
    (): void => {
      if (!token) {
        redirect(RoutingPath.Home);
      }
    },
    [token],
  );

  const handleCloseAlertAction: () => void = (): void => {
    hideFullscreenAlert();
    redirect(RoutingPath.SignIn);
  };

  const handleChangePassword: (values: ResetPasswordData) => void = (values: ResetPasswordData): void => {
    if (!token) {
      return;
    }

    setIsButtonLoading(true);
    resetPassword(values.password, token)
      .then((): void => {
        showFullscreenAlert({
          title: t('chic.crmApp.global.success'),
          description: t('chic.crmApp.changePasswordView.resetPasswordPopup.subheader'),
          fullscreenAlertTheme: FullscreenAlertTheme.Success,
          acceptButtonSettings: { label: t('chic.crmApp.global.signIn'), action: handleCloseAlertAction },
        });
      })
      .catch((error: FrontendApiError): void => {
        if (error.errors.password) {
          addToast({
            content: error.errors.password.enum === ErrorMessageType.PasswordHistorySame
              ? t('chic.crmApp.errors.password_history_same')
              : t('chic.crmApp.errors.password_not_valid'),
          });
        }
        if (error.errors.token?.enum === ErrorMessageType.TokenInvalid) {
          addToast({ content: t('chic.crmApp.errors.token_invalid') });
          redirect(RoutingPath.SignIn);
        }
      })
      .finally((): void => setIsButtonLoading(false));
  };

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle label={t('chic.crmApp.changePasswordView.header')} size={PageTitleSize.Big} />
      </PageTitleWrapper>
      <InnerContainer>
        <Description text={t('chic.crmApp.changePasswordView.description')} />
        <Formik
          validationSchema={ChangePasswordValidationSchema}
          initialValues={{ password: '', repeatedPassword: '' }}
          onSubmit={handleChangePassword}
        >
          {({ errors, touched, values, setFieldValue }: FormikProps<ResetPasswordData>): JSX.Element => (
            <Form>
              <StyledInput
                label={t('chic.crmApp.changePasswordView.password.label')}
                type='password'
                onChange={(value: string): void => setFieldValue('password', value)}
                value={values.password}
                placeholder={t('chic.crmApp.changePasswordView.password.placeholder')}
                inputTheme={errors.password && touched.password ? InputTheme.Error : InputTheme.Standard}
                description={touched.password ? errors.password : undefined}
                colorTheme={ComponentColorTheme.IC}
                required
              />
              <StyledInput
                label={t('chic.crmApp.changePasswordView.repeatedPassword.label')}
                type='password'
                onChange={(value: string): void => setFieldValue('repeatedPassword', value)}
                value={values.repeatedPassword}
                placeholder={t('chic.crmApp.changePasswordView.repeatedPassword.placeholder')}
                inputTheme={errors.repeatedPassword && touched.repeatedPassword ? InputTheme.Error : InputTheme.Standard}
                description={touched.repeatedPassword ? errors.repeatedPassword : undefined}
                colorTheme={ComponentColorTheme.IC}
                required
              />
              <StyledButton 
                type='submit' 
                label={t('chic.crmApp.global.save')} 
                buttonTheme={ButtonTheme.ICPrimary} 
                isLoading={isButtonLoading}
              />
            </Form>
          )}
        </Formik>
      </InnerContainer>
    </Container>
  );
};
