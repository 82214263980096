import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { UserProfile } from '@chic/models';
import { getUserProfile } from '@chic/api';

import { CompanyBox, CompanyData, Container, InputsWrapper, StyledPageTitle } from './personalData.styled';
import { ComponentColorTheme, Input, UseState } from '@chic-loyalty/ui';

export const PersonalData: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [profileData, setProfileData]: UseState<UserProfile | null> = useState<UserProfile | null>(null);

  useEffect(
    (): void => {
      if (!profileData) {
        getUserProfile().then(setProfileData);
      }
    },
    [],
  );

  return (
    <Container>
      <StyledPageTitle label={t('chic.crmApp.personalData.title')} />
      <InputsWrapper>
        <Input 
          label={t('chic.crmApp.personalData.name.label')} 
          value={profileData?.name || '-'} 
          colorTheme={ComponentColorTheme.IC} 
          disabled 
          required 
        />
        <Input 
          label={t('chic.crmApp.personalData.surname.label')} 
          value={profileData?.surname || '-'} 
          colorTheme={ComponentColorTheme.IC} 
          disabled 
          required 
        />
        <Input 
          label={t('chic.crmApp.personalData.email.label')}
          value={profileData?.email || '-'} 
          colorTheme={ComponentColorTheme.IC} 
          disabled 
          required 
        />
      </InputsWrapper>
      <StyledPageTitle label={t('chic.crmApp.companyPosition.title')} />
      {profileData?.company ? (
        <CompanyBox>
          <CompanyData>{profileData.company.name}</CompanyData>
          <CompanyData>
            {`${profileData.company.street} ${profileData.company.number}, ${profileData.company.postal} ${profileData.company.city}`}
          </CompanyData>
        </CompanyBox>
      ) : <CompanyData>-</CompanyData>}
    </Container>
  );
};
