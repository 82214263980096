import { useRef, useEffect, MutableRefObject } from 'react';
import { appConfig } from '../appConfig.config';

export const useTitle = (title: string): void => {
  const defaultTitle: MutableRefObject<string> = useRef(document.title);

  useEffect(
    (): void => {
      document.title = `${title} | ${appConfig.applicationName}`;
    },
    [title],
  );

  useEffect(
    (): (() => void) => {
      return ((): void => { document.title = defaultTitle.current; });
    },
    [],
  );
};
