import React, { useEffect, useMemo, useReducer } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { GlobalStyle } from '../theme/globalStyle.theme';
import {
  DashboardView,
  NotFoundView,
  CrmSignInView,
  CrmSignInRedirectView,
  ChangePasswordView,
  SignInView,
  CrmSignInAfterRedirectView,
  ProfileView,
  RemindPasswordView,
  UserRoleVerificationListView,
  UserRoleVerificationView,
  SubscriptionsView,
  ActivationsView,
} from '../views';
import { AppWrapper, CheckAuth } from '@chic/components';
import { LocalStorageKey, RoutingPath } from '@chic/enums';
import { AppState, AuthContextType, AuthState, UseInitial } from '@chic/models';
import { useInitial } from '@chic/hooks';
import { appStateReducer, authReducer } from '@chic/reducers';
import { AuthContext, ConfigsContext, AppStateContext } from '@chic/contexts';
import { initialAppState } from '@chic/constans';
import { ComponentColorTheme, FullscreenAlertSettings, NotificationsProvider, UseLocalStorage, useLocalStorage } from '@chic-loyalty/ui';

const queryClient: QueryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } });

const App: React.FC = (): JSX.Element => {
  const [loggedUserData]: UseLocalStorage<string | null> = useLocalStorage<string | null>(LocalStorageKey.LoggedUserData, '');
  const [serializedAppState, setAppStateStorage]: UseLocalStorage<string | null> = useLocalStorage<string | null>(
    LocalStorageKey.AppState, '',
  );
  const [authState, authDispatch] = useReducer(authReducer, JSON.parse(loggedUserData || '{}') as AuthState);
  const appStateFromLocalStorage: AppState | null = serializedAppState ? (serializedAppState || '{}') as unknown as AppState : null;
  const [appState, appStateDispatch] = useReducer(
    appStateReducer,
    {
      ...initialAppState,
      ...(appStateFromLocalStorage ?? {}),
    },
  );
  const valueForAuthContext: AuthContextType = useMemo((): AuthContextType => [authState, authDispatch], [authState]);
  const { configs }: UseInitial = useInitial();

  // TODO: Fix NotificationsProvider to take initial state and fix serialization for functions
  const fullscreenAlertData: FullscreenAlertSettings | undefined = useMemo(
    (): FullscreenAlertSettings | undefined => {
      if (appState.fullscreenAlertData) {
        return appState.fullscreenAlertData;
      }
    },
    [appStateFromLocalStorage],
  );

  useEffect(
    (): void => {
      setAppStateStorage(JSON.stringify(appState));
    },
    [appState],
  );

  // TODO: add loader
  return configs ? (
    <NotificationsProvider colorTheme={ComponentColorTheme.IC}>
      <ConfigsContext.Provider value={configs}>
        <AuthContext.Provider value={valueForAuthContext}>
          <QueryClientProvider client={queryClient}>
            <AppStateContext.Provider value={[appState, appStateDispatch]}>
              <Router>
                <GlobalStyle />
                <AppWrapper fullscreenAlertData={fullscreenAlertData}>
                  <Routes>
                    {/* common */}
                    <Route path={RoutingPath.SignIn} element={
                      <CheckAuth><SignInView /></CheckAuth>
                    } />
                    <Route path={RoutingPath.RemindPassword} element={
                      <CheckAuth><RemindPasswordView /></CheckAuth>
                    } />
                    <Route path={RoutingPath.ChangePassword} element={
                      <CheckAuth><ChangePasswordView /></CheckAuth>
                    } />
                    <Route path={RoutingPath.CrmSignIn} element={
                      <CheckAuth><CrmSignInView /></CheckAuth>
                    } />
                    <Route path={RoutingPath.CrmSignInRedirect} element={
                      <CheckAuth><CrmSignInRedirectView /></CheckAuth>
                    } />
                    <Route path={RoutingPath.ReturnFromCrm} element={
                      <CheckAuth><CrmSignInAfterRedirectView /></CheckAuth>
                    } />
                    <Route path={RoutingPath.Dashboard} element={
                      <CheckAuth><DashboardView /></CheckAuth>
                    } />
                    <Route path={RoutingPath.Profile} element={
                      <CheckAuth><ProfileView /></CheckAuth>
                    } />
                    <Route path={RoutingPath.UserRoleVerificationList} element={
                      <CheckAuth><UserRoleVerificationListView /></CheckAuth>
                    } />
                    <Route path={RoutingPath.UserRoleVerification} element={
                      <CheckAuth><UserRoleVerificationView /></CheckAuth>
                    } />
                    {/* custom for forks apps */}
                    <Route path={RoutingPath.Activations} element={
                      <CheckAuth><ActivationsView /></CheckAuth>
                    } />
                    <Route path={RoutingPath.Subscriptions} element={
                      <CheckAuth><SubscriptionsView /></CheckAuth>
                    } />
                    <Route path={RoutingPath.NotFound} element={
                      <CheckAuth><NotFoundView /></CheckAuth>
                    } />
                  </Routes>
                </AppWrapper>
              </Router>
            </AppStateContext.Provider>
          </QueryClientProvider>
        </AuthContext.Provider>
      </ConfigsContext.Provider>
    </NotificationsProvider>
  ) : <></>;
};

export default App;
