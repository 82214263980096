import { useContext } from 'react';

import { AppStateContext } from '@chic/contexts';
import { AppStateContextType } from '@chic/models';

export const useAppState: () => AppStateContextType = (): AppStateContextType => {
  const appStateContextValue: AppStateContextType = useContext(AppStateContext);
  if (!appStateContextValue) {
    throw new Error('Cannot use app state context outside config.provider');
  }

  return appStateContextValue;
};
