import { CalcSize, Color, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - ${CalcSize.FooterHeight});
  background-color: ${Color.ICBlack500};
`;

export const Message: StyledComponent<'div'> = styled.div`
  margin-top: 20px;
  font-size: 14px; 
  line-height: 24px;
  color: ${Color.ICWhite100};
`;
