import { IconName } from '@chic-loyalty/ui';
import { PermissionScopeName, RoutingPath } from '@chic/enums';
import { MenuPosition } from '@chic/models';

export const menuConfig: MenuPosition[] = [
  {
    pathname: RoutingPath.Dashboard,
    label: 'Home',
    inactiveIcon: IconName.Home,
    activeIcon: IconName.HomeActive,
    iconSize: 20,
  },
  {
    pathname: RoutingPath.UserRoleVerificationList,
    label: 'Weryfikacja użytkowników',
    inactiveIcon: IconName.Reports,
    activeIcon: IconName.ReportsActive,
    iconSize: 20,
    requiredPermissions: [PermissionScopeName.UserVerificationList],
    mobileLabel: 'Weryfikacja',
  },
  {
    pathname: RoutingPath.Activations,
    label: 'Aktywacje',
    inactiveIcon: IconName.Offers,
    activeIcon: IconName.OffersActive,
    iconSize: 20,
  },
  {
    pathname: RoutingPath.Subscriptions,
    label: 'Inspiration Plan',
    inactiveIcon: IconName.Subscription,
    activeIcon: IconName.SubscriptionActive,
    iconSize: 20,
  },
  {
    pathname: RoutingPath.Profile,
    label: 'Profil',
    inactiveIcon: IconName.Account,
    activeIcon: IconName.AccountActive,
    iconSize: 20,
  },
];
